import { groq } from 'next-sanity';
import { LinkType } from '../helpers';
import { linkQueryBody } from '../helpers/link';
import { mediaItemsQuery } from '../helpers/mediaItems';
import { imageQuery } from '../helpers/image';

export const pageHeroQuery = groq`
  pageHero {
    _type,
    _key,
    backgroundColor,
    title,
    text,
    richText,
    ${imageQuery},
    ${mediaItemsQuery},
    button { 
      ${linkQueryBody}
    }
  }
`;

export interface PageHeroBlock extends Omit<Sanity.Keyed<Sanity.Schema.PageHero>, 'button'> {
  button?: LinkType;
}
