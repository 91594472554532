import { Chevron, Pentagon } from '../Icons';
import { ButtonHTMLAttributes, MouseEventHandler, forwardRef } from 'react';
import cx from 'classnames';
import styles from './ChevronButtonInPentagon.module.css';

interface Properties extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  pointLeft?: boolean;
  small?: boolean;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
}

export const ChevronButtonInPentagon = forwardRef<HTMLButtonElement, Properties>(
  ({ onClick, pointLeft, small, type = 'button', ...rest }, reference) => (
    <button
      className={cx(styles.chevronButtonInPentagon, { [styles.pointLeft]: pointLeft, [styles.small]: small })}
      onClick={onClick}
      // eslint-disable-next-line react/button-has-type
      type={type}
      ref={reference}
      {...rest}>
      <Pentagon />
      <Chevron />
    </button>
  )
);

ChevronButtonInPentagon.displayName = 'ChevronButtonInPentagon';
