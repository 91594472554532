import { groq } from 'next-sanity';
import { createHrefQueryWithParents } from './createHrefQueryWithParents';

export interface LinkQuery {
  externalLink?: string;
  href?: { _type?: Sanity.Schema.Document['_type']; slug?: { current: string } };
  label?: string;
  targetBlank?: boolean;
  type?: 'reference' | 'external' | 'noLink';
}

export const linkQueryBody = groq`
   _key,
   _type,
   type,
   label,
   externalLink,
   targetBlank,
  ${createHrefQueryWithParents()}
`;

export const linkQuery = groq`
link {
  ${linkQueryBody}
}
`;

export const contentLinkQuery = groq`{
  ...,
  markDefs[] {
    _key,
    _type,
    ...link {
      ...,
      ${createHrefQueryWithParents()}
    }
  }
 }`;
