import { groq } from 'next-sanity';
import { imageQuery } from '../helpers/image';
import { contentLinkQuery } from '../helpers/link';

const NAME = 'imageAndText';

export const imageAndTextQuery = groq`
  _type == '${NAME}' => {
    _type,
    _key,
    title,
    content[] ${contentLinkQuery},
    backgroundColor,
    reversed,
    video,
    ${imageQuery},
  },
`;

export type ImageAndTextBlock = Sanity.Keyed<Sanity.Schema.ImageAndText>;
