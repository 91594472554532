import { groq } from 'next-sanity';
import { createImageTitleAndAltFallbacks } from '@klokgroep/sanity/src/queries/helpers/image';
import { contentLinkQuery } from '../../queries/helpers/link';

export const NAME = 'mediaBlock';

export const mediaQuery = groq`
  _type == '${NAME}' => {
    _type,
    title,
    description[] ${contentLinkQuery},
    items[] {
      ...,
      ${createImageTitleAndAltFallbacks()}
    },
    backgroundColor,
    fullWidth,
    heightSize
  },
`;

export interface MediaBlock extends Omit<Sanity.Keyed<Sanity.Schema.MediaBlock>, '_type'> {
  _type: 'mediaBlock';
  noMarginOverflow?: boolean;
}
