import { groq } from 'next-sanity';
import { imageQuery } from '../helpers/image';
import { contentLinkQuery, linkQueryBody } from '../helpers/link';

const NAME = 'xlImageWithRichTextAndButton';

export const xlImageWithRichTextAndButtonQuery = groq`
  _type == '${NAME}' => {
    ...,
    content[] ${contentLinkQuery},
    ${imageQuery},
    button {
      ${linkQueryBody}
    },
    textAlignment,
  },
`;

export interface XlImageWithRichTextAndButtonBlock
  extends Omit<Sanity.Keyed<Sanity.Schema.XlImageWithRichTextAndButton>, 'button'> {
  button?: {
    label?: string;
    href?: { _type: Sanity.Schema.Document['_type']; slug?: { _type: 'slug'; current?: string } };
  };
}
