import { getClient } from '../sanity.server';
import { groq } from 'next-sanity';
import { MappedContentModules, getContentModulesQueries } from './blocks/contentModules';
import { PageHeroBlock, pageHeroQuery } from './blocks/pageHero';
import { SeoQuery, createSeoQuery } from './helpers/seoQuery';
import { createParentsQuery } from './helpers/createHrefQueryWithParents';
import { SiteIdType } from '@klokgroep/sanity/src/sites';

interface PageWithParentReference {
  _id: string;
  slug: {
    current: string;
  };
  parent?: {
    _ref: string;
  };
}

export const getPageSlugs = async (preview = false, filterNoIndex?: boolean): Promise<string[][]> => {
  const pages = await getClient(preview).fetch<PageWithParentReference[]>(
    groq`*[_type == 'holding-page' && defined(slug.current)${
      filterNoIndex ? ` && seo.noIndex != true && parent->seo.noIndex != true` : ''
    }] {
      _id,
      slug,
      parent{ _ref }
    }`
  );

  const pagesWithoutParent = pages.filter((page) => page.parent === null);
  const pagesWithParent = pages.filter((page) => page.parent !== null);

  const slugPossibilities: PageWithParentReference[][] = [];

  for (const page of pagesWithoutParent) {
    slugPossibilities.push([page]);
  }

  while (pagesWithParent.length > 0) {
    const previousLength = pagesWithParent.length;
    for (let index = 0; index < pagesWithParent.length; index++) {
      const pageWithParent = pagesWithParent[index];
      const parentIndex = slugPossibilities.findIndex((pages) => pages.at(-1)?._id === pageWithParent.parent?._ref);
      if (parentIndex === -1) {
        continue;
      }
      slugPossibilities.push([...slugPossibilities[parentIndex], pageWithParent]);
      pagesWithParent.splice(index, 1);
    }
    if (pagesWithParent.length === previousLength) {
      break;
    }
  }

  return slugPossibilities.map((pages) => pages.map((page) => page.slug.current));
};

export interface GetPageForSlugsResponse {
  _id: Sanity.Schema.HoldingPage['_id'];
  contentModules: MappedContentModules;
  pageHero?: PageHeroBlock;
  footerColor?: 'white' | 'lightGray';
  seo?: SeoQuery;
  slug: { current: string };
  parent?: PageParent;
}

export interface PageParent {
  _id: Sanity.Schema.HoldingPage['_id'];
  slug: { current: string };
  parent?: PageParent;
}

export const getHoldingPageQuery = (
  siteId: SiteIdType,
  preview?: boolean
) => groq`*[_type == 'holding-page' && lower(slug.current) == lower($slug)] | order(_updatedAt desc)[0]  {
  _id,
  _type,
  footerColor,
  slug,
  ${createSeoQuery()},
  ${pageHeroQuery},
  contentModules[] { ...select( ${getContentModulesQueries(siteId, preview)} ), },
  ${createParentsQuery()}
}`;

export const getPageForSlugs = async (slugs: string[], preview = false, siteId: SiteIdType) => {
  const endingSlug = slugs.at(-1);

  const result = await getClient(preview).fetch<GetPageForSlugsResponse>(getHoldingPageQuery(siteId, preview), {
    slug: endingSlug,
  });

  return result;
};
