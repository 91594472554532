import Head from 'next/head';

interface ImageContext {
  url: string;
  caption: string;
  height: number;
  width: number;
}

interface ParentOrganisationContext {
  name: string;
  description: string;
  url: string;
  logo: string;
}

interface OrganisationContext {
  name: string;
  legalName: string;
  logo: ImageContext;
  mediaUrls: string[];
  founderName: string;
  founderPage?: string;
  foundingDate: string;
  numberOfEmployees: number;
  slogan: string;
  description: string;
  parentOrganisation?: ParentOrganisationContext;
}

interface PageContext {
  url: string;
  datePublished?: string;
  dateModified?: string;
  title: string;
  description: string;
  type: 'WebPage' | 'Article' | 'CollectionPage' | 'ContactPage';
}

interface SchemaContext {
  baseUrl: string;
  locale: string;
  organisation: OrganisationContext;
  page: PageContext;
}

export const generateParentOrganisation = (parentOrganisation?: ParentOrganisationContext) => {
  if (!parentOrganisation) {
    return;
  }

  return {
    '@type': 'Organization',
    name: parentOrganisation?.name,
    description: parentOrganisation?.description,
    url: parentOrganisation?.url,
    sameAs: [parentOrganisation?.url],
    logo: parentOrganisation?.logo,
  };
};

export const generateSchema = (context: SchemaContext) => {
  const pageId = context.page.url;
  const websiteId = `${context.baseUrl}#website`;
  const organisationId = `${context.baseUrl}#organisation`;
  const logoId = `${context.baseUrl}#logo`;

  return JSON.stringify({
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': context.page.type,
        '@id': pageId,
        url: context.page.url,
        name: context.page.title,
        isPartOf: { '@id': websiteId },
        datePublished: context.page.datePublished,
        dateModified: context.page.dateModified,
        description: context.page.description,
        inLanguage: context.locale,
        potentialAction: { '@type': 'ReadAction', target: [context.page.url] },
      },
      {
        '@type': 'WebSite',
        '@id': websiteId,
        url: context.baseUrl,
        name: context.organisation.name,
        description: context.organisation.description,
        publisher: { '@id': organisationId },
        inLanguage: context.locale,
        copyrightHolder: { '@id': organisationId },
      },
      {
        '@type': ['Organization', 'Brand'],
        '@id': organisationId,
        name: context.organisation.name,
        url: context.baseUrl,
        logo: {
          '@type': 'ImageObject',
          '@id': logoId,
          inLanguage: context.locale,
          url: context.organisation.logo.url,
          contentUrl: context.organisation.logo.url,
          width: context.organisation.logo.width,
          height: context.organisation.logo.height,
          caption: context.organisation.name,
        },
        image: { '@id': logoId },
        sameAs: context.organisation.mediaUrls,
        founder: {
          '@type': 'Person',
          name: context.organisation.founderName,
          url: context.organisation.founderPage,
          sameAs: context.organisation.founderPage,
        },
        foundingDate: context.organisation.foundingDate,
        numberOfEmployees: context.organisation.numberOfEmployees,
        slogan: context.organisation.slogan,
        description: context.organisation.description,
        legalName: context.organisation.legalName,
        parentOrganization: generateParentOrganisation(context.organisation.parentOrganisation),
      },
    ],
  });
};

interface SchemaProperties {
  organisationContext: OrganisationContext;
  pageContext: PageContext;
  baseUrl: string;
  locale: string;
}

export const Schema = ({ organisationContext, pageContext, baseUrl, locale }: SchemaProperties) => {
  const schema = generateSchema({
    organisation: organisationContext,
    page: pageContext,
    baseUrl,
    locale,
  });

  return (
    <Head>
      <script id="schema" type="application/ld+json" dangerouslySetInnerHTML={{ __html: schema }} />
    </Head>
  );
};
