import {
  createHrefForTypeAndSlug,
  LinkType,
  ReferenceType,
  SiteIdType,
  SITES,
  translatePagesPathToPathname,
} from '@klokgroep/sanity';

// TODO: share this
const KLOKGROEP_REFERRER = 'klokgroep';

export function createHref(
  link?: LinkType,
  onError = console.error,
  siteId?: SiteIdType,
  queryParameterString?: string
): string {
  if (!link) {
    onError('createHref:noLink', link);
    return translatePagesPathToPathname('/');
  }

  const { type, href, externalLink, locale } = link;

  if (type === 'noLink') {
    return translatePagesPathToPathname('', locale);
  }

  if (type === 'external') {
    return translatePagesPathToPathname(externalLink || '/', locale);
  }

  if (type === 'reference') {
    // If the link is to a shared job, we want to link it to its corporate site, and not the current site.
    return typeof href !== 'string' && href?._type === 'shared-job' && siteId
      ? translatePagesPathToPathname(createExternalSiteLink({ href, siteId, onError, queryParameterString }), locale)
      : translatePagesPathToPathname(createHrefForTypeAndSlug(href as ReferenceType, onError), locale);
  }

  if (!type && href) {
    onError('createHref:typeNotSet', link);
    return translatePagesPathToPathname(createHrefForTypeAndSlug(href as ReferenceType, onError), locale);
  }

  onError('createHref:invalidLink', link);
  return translatePagesPathToPathname('/', locale);
}

export function createExternalSiteLink({
  href,
  siteId,
  onError,
  queryParameterString,
}: {
  href: ReferenceType;
  siteId?: SiteIdType;
  onError?: {
    (...data: any[]): void;
    (message?: any, ...optionalParameters: any[]): void;
  };
  queryParameterString?: string;
}) {
  const hrefToUse = createHrefForTypeAndSlug(href as ReferenceType, onError);

  if (!siteId) {
    siteId = 'holding';
  }

  const siteUrl = process.env.NEXT_PUBLIC_IS_ACCEPTANCE
    ? SITES.find((site) => site.siteId === siteId)?.acceptanceHostname
    : process.env.NEXT_PUBLIC_IS_DEVELOPMENT
    ? SITES.find((site) => site.siteId === siteId)?.previewHostname
    : process.env.NODE_ENV === 'production'
    ? SITES.find((site) => site.siteId === siteId)?.productionHostname
    : SITES.find((site) => site.siteId === siteId)?.developmentHostname;

  if (
    typeof window !== 'undefined' &&
    (window?.location?.host?.includes('klokgroep') || window?.location?.host?.includes('localhost:3000'))
  ) {
    return `${siteUrl}${hrefToUse}?${queryParameterString || ''}${`&referrer=${KLOKGROEP_REFERRER}`}`;
  }

  return `${siteUrl}${hrefToUse}?${queryParameterString || ''}`;
}
