import { getClient as getClientSideClient } from '../sanity.client';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import imageUrlBuilder from '@sanity/image-url';
import { ImageUrlBuilder } from '@sanity/image-url/lib/types/builder';

let builder: ImageUrlBuilder;

export function imageUrlFor(source: SanityImageSource) {
  if (!builder) {
    builder = imageUrlBuilder(getClientSideClient());
  }
  return builder.image(source);
}
