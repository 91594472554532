/**
 * Server-side Sanity utilities. By having these in a separate file from the
 * utilities we use on the client side, we are able to tree-shake (remove)
 * code that is not used on the client side.
 */
import { createClient } from 'next-sanity';
import { getSanityConfig } from './sanityConfig';

export const getClient = (preview: boolean, writeAccessRequired?: boolean) => {
  const sanityConfig = getSanityConfig();
  return preview
    ? createClient({
        ...sanityConfig,
        useCdn: false,
        // Fallback to using the WRITE token until https://www.sanity.io/docs/vercel-integration starts shipping a READ token.
        // As this client only exists on the server and the token is never shared with the browser, we don't risk escalating permissions to untrustworthy users
        token: process.env.SANITY_API_READ_TOKEN || process.env.SANITY_API_WRITE_TOKEN,
      })
    : createClient({ ...sanityConfig, token: writeAccessRequired ? process.env.SANITY_API_WRITE_TOKEN : undefined });
};
