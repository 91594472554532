import { groq } from 'next-sanity';
import { contentLinkQuery } from '../helpers/link';

export const NAME = 'jobApplicationProcess';

export const jobApplicationProcessQuery = groq`
  _type == '${NAME}' => {
    _type,
    _key,
    backgroundColor,
    reversed,
    leftContent[] ${contentLinkQuery},
    rightContent[] ${contentLinkQuery},
    contactImage,
    contactNameAndFunction,
    contactPhoneNumber,
    contactEmail,
  },
`;

export type JobApplicationProcessBlock = Sanity.Keyed<Sanity.Schema.JobApplicationProcess>;
