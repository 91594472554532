import { groq } from 'next-sanity';

const NAME = 'titleVideo';

export const titleVideoQuery = groq`
  _type == '${NAME}' => {
    _type,
    _key,
    backgroundColor,
    title,
    videoUrl,
  },
`;

export type TitleVideoBlock = Sanity.Keyed<Sanity.Schema.TitleVideo> & {
  noMarginOverflow?: boolean;
};
