import { getClient } from '../sanity.server';
import { groq } from 'next-sanity';
import { MappedContentModules, getContentModulesQueries } from './blocks/contentModules';
import { SeoQuery, createSeoQuery } from './helpers/seoQuery';
import { createGetBlogPostsQuery, GetBlogPostsResponse } from './blogPosts';
import { imageQuery } from './helpers/image';
import { contentLinkQuery } from '../queries/helpers/link';
import { SiteIdType } from '@klokgroep/sanity/src/sites';

export type WithImage<T> = T & {
  image?: { alt?: string; src?: string };
};

export interface HomepageResponse extends Omit<Sanity.Schema.HoldingHomepage, 'seo' | 'contentModules'> {
  _id: string;
  _type: 'holding-homepage';
  seo: SeoQuery;
  hideTitle?: boolean;
  contentModules: MappedContentModules;
  blogPosts: GetBlogPostsResponse[];
}

export const getHoldingHomepageQuery = (
  siteId: SiteIdType,
  preview?: boolean
) => groq`*[_type == 'holding-homepage'] | order(_updatedAt desc)[0] {
  _id,
  _type,
  ${createSeoQuery()},
  contentModules[] { ...select( ${getContentModulesQueries(siteId, preview)} ), },
  homepageHero {
    ...,
    ${imageQuery},
  },
  hideTitle,
  mediaType,
  imageAndText{
    ...,
    content[] ${contentLinkQuery},
   ${imageQuery},
  },
  videoUrl,
  "blogPosts": ${createGetBlogPostsQuery(undefined, true, 5)}
}`;

export const getHoldingHomepage = async (siteId: SiteIdType, preview = false) =>
  getClient(preview).fetch<HomepageResponse>(getHoldingHomepageQuery(siteId, preview));
