interface Parent {
  _type?: Sanity.Schema.Document['_type'];
  slug?: { current?: string };
  parent: Parent;
}

export function createHrefForTypeAndSlug(
  document:
    | {
        _type?: Sanity.Schema.Document['_type'];
        slug?: { current?: string };
        parent?: Parent;
      }
    | undefined,
  onError = console.error
): string {
  if (!document) {
    onError('createHrefForTypeAndSlug:without-document', { tags: document });
    return '/404';
  }

  if (document._type === 'holding-homepage') {
    return '/';
  }

  if (document._type === 'corporate-homepage') {
    return '/';
  }

  if (document._type === 'holding-contactPage') {
    return '/contact';
  }

  if (document._type?.includes('corporate-contactPage')) {
    return '/contact';
  }

  if (document._type?.includes('jobsOverviewPage')) {
    return '/vacatures';
  }

  if (document._type?.includes('projectsOverviewPage')) {
    return '/projecten';
  }

  if (document._type?.includes('newsPage')) {
    return '/nieuws';
  }

  // Note everything that does not need a slug needs to be above this
  if (!document.slug?.current) {
    onError('createHrefForTypeAndSlug:without-slug', { tags: document });
    return '/404';
  }

  if (document._type === 'jobCategoryTaxonomy') {
    return `/vacatures?page=1&categories=${document.slug.current}`;
  }

  if (document._type === 'categoryTaxonomy') {
    return `/nieuws?page=1&categories=${document.slug.current}`;
  }

  if (document._type === 'specialismTaxonomy') {
    return `/nieuws?page=1&specialisms=${document.slug.current}`;
  }

  if (document._type === 'corporate-project') {
    return `/projecten/${document.slug.current}`;
  }

  //TODO: make this work for external links to and from corporate sites
  if (document._type === 'blogPost') {
    return `/nieuws/${document.slug?.current}`;
  }

  if (document._type === 'holding-theme') {
    return `/themas/${document.slug?.current}`;
  }

  if (document._type === 'holding-page' || document._type === 'corporate-page') {
    if (document.parent) {
      return `${createHrefForTypeAndSlug(document.parent)}/${document.slug?.current}`;
    }
    return `/${document.slug?.current}`;
  }

  if (document._type === 'shared-job') {
    return `/vacatures/${document.slug?.current}`;
  }

  onError('createHrefForTypeAndSlug:not-found', { tags: document });

  return `/404`;
}
