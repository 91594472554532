import { groq } from 'next-sanity';
import { contentLinkQuery } from '../helpers/link';

const NAME = 'textAndFoldouts';

export const textAndFoldoutsQuery = groq`
  _type,
  _key,
  title,
  content[] ${contentLinkQuery},
  backgroundColor,
  reversed,
  items[] {
    ...,
    content[] ${contentLinkQuery}
  },
`;

export const textAndFoldoutsModuleQuery = groq`
  _type == '${NAME}' => {
    ${textAndFoldoutsQuery}
  },
`;

export type TextAndFoldoutsBlock = Sanity.Keyed<Sanity.Schema.TextAndFoldouts>;
