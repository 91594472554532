import { SanityImageType } from '@klokgroep/sanity';
import { SourceSetSanityImage } from '@klokgroep/shared-components/src/SourceSet';
import styles from './ContactPerson.module.css';
import { useCommonTranslations } from '@klokgroep/shared-components/utils/useTranslations';
import { useEffect, useState } from 'react';
import cx from 'classnames';
import Link from 'next/link';

interface Properties {
  contactPerson?: Omit<Sanity.Schema.SharedContactPerson, 'image'> & {
    headerTitle?: string;
    image?: SanityImageType;
  };
}

const IMAGE_SIZES = [
  { width: 160, minWidth: 300, ratio: 1 },
  { width: 80, minWidth: 1, ratio: 1 },
];

export const ContactPerson = ({ contactPerson }: Properties) => {
  const t = useCommonTranslations();
  const [scrollPosition, setScrollPosition] = useState(0);
  const triggerValue = 400;

  const handleScroll = () => {
    requestAnimationFrame(() => {
      setScrollPosition(window.scrollY);
    });
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return contactPerson?.image ? (
    <div className={cx(styles.contactPerson, scrollPosition > triggerValue ? styles.isVisible : undefined)}>
      <div className={styles.info}>
        <span className={styles.headerTitle}>{contactPerson.headerTitle}</span>
        <Link className={styles.button} href={`mailto:${contactPerson.email}`}>
          {t('job_contact_person_header_buttonlink')}
        </Link>
      </div>
      <SourceSetSanityImage sizes={IMAGE_SIZES} asset={contactPerson?.image} maxWidth={80} />
    </div>
  ) : undefined;
};
