import { groq } from 'next-sanity';

const NAME = 'carousel';

export const carouselQuery = groq`
  _type == '${NAME}' => {
    _type,
    _key,
    autoPlay,
    "items": items[]{
      ...image,
      "alt": coalesce(image.alt,image.asset->altText,string::split(image.asset->originalFilename,'.')[0]),
      "title": coalesce(title, null)
    },
  },
`;

export type CarouselBlock = Omit<Sanity.Keyed<Sanity.Schema.Carousel>, 'items'> & {
  items: ({
    alt?: string;
    title?: string;
  } & NonNullable<Sanity.Schema.Carousel['items']>[number]['image'])[];
  noMarginOverflow?: boolean;
};
