import { groq } from 'next-sanity';
import { LinkType, SanityImageType } from '../../queries/helpers';
import { contentLinkQuery, linkQueryBody } from '../../queries/helpers/link';
import { MediaItemsType, mediaItemsQuery } from '../../queries/helpers/mediaItems';
import { imageQuery } from '../../queries/helpers/image';

export interface CorporatePageHeroQuery {
  pageHero?: {
    _type?: string;
    _key?: string;
    title?: string;
    text?: string;
    richText?: Array<Sanity.Keyed<Sanity.Block>>;
    mediaItems?: MediaItemsType;
    image?: SanityImageType;
    button?: LinkType;
    highlightedContentBlock?: {
      highlightedText?: Array<Sanity.Keyed<Sanity.Block>>;
      button?: LinkType;
    };
  };
}

export const corporatePageHeroQuery = groq`
  pageHero {
    _type,
    _key,
    title,
    text,
    richText[] ${contentLinkQuery},
    ${imageQuery},
    ${mediaItemsQuery},
    button { ${linkQueryBody} },
    highlightedContentBlock {
      highlightedText[] ${contentLinkQuery},
      button { ${linkQueryBody} }
    },
  }
`;
