import { SanityImageType, imageUrlFor } from '@klokgroep/sanity';
import { Fragment, HTMLAttributes, useId, useMemo } from 'react';
import styles from './SourceSet.module.css';
import cx from 'classnames';

const DEFAULT_SIZES = [
  { width: 1400, minWidth: 1300 },
  { width: 1300, minWidth: 1200 },
  { width: 1200, minWidth: 1100 },
  { width: 1100, minWidth: 1000 },
  { width: 1000, minWidth: 900 },
  { width: 900, minWidth: 800 },
  { width: 800, minWidth: 768 },
  { width: 768, minWidth: 700 },
  { width: 700, minWidth: 600 },
  { width: 600, minWidth: 500 },
  { width: 500, minWidth: 400 },
  { width: 400, minWidth: 300 },
  { width: 300, minWidth: 1 },
];

interface ExtendedSanityImageType extends Omit<SanityImageType, 'size'> {
  size?: 'small' | 'medium' | 'portrait' | 'landscape' | 'square';
}

interface Properties {
  asset: NonNullable<ExtendedSanityImageType>;
  loading?: 'eager' | 'lazy';
  maxWidth?: number;
  sizes?: { width: number; minWidth: number; ratio?: number }[];
  draggable?: HTMLAttributes<HTMLSourceElement>['draggable'];
  withoutHotspot?: boolean;
}

export const SourceSetSanityImage = ({
  asset,
  loading = 'lazy',
  maxWidth = DEFAULT_SIZES[0].width,
  draggable = 'true',
  sizes = DEFAULT_SIZES,
  withoutHotspot = false,
}: Properties) => {
  const sizesToUse = useMemo(() => sizes.filter(({ width }) => width <= maxWidth), [maxWidth, sizes]);

  /*
  Here we set a unique class name for the picture element. This is needed to make sure that the aspect 
  ratio styling we generate is only applied to the image inside the picture element and not to other 
  images on the page. The aspect ratio is responsive and thus created with a media query. This media 
  query is only applied to this picture by using the generated uniqueClassName. The styling is 
  placed inline in the style tag you see below.
  */
  const id = useId();
  const uniqueClassName = useMemo(() => `source-set-${id}`, [id]);
  const aspectRatioStyle = useMemo(
    () =>
      [...(sizesToUse || [])]
        ?.reverse()
        .filter((size, index, self) => self.findIndex((s) => s.ratio === size.ratio) === index)
        .map(
          ({ minWidth, ratio }) =>
            `.${uniqueClassName} { @media (min-width: ${minWidth}px) { aspect-ratio: ${ratio}; } }`
        )
        .join(' '),
    [sizesToUse, uniqueClassName]
  );

  return (
    <Fragment>
      <style dangerouslySetInnerHTML={{ __html: aspectRatioStyle }} />
      <picture
        className={cx(styles.container, asset.size == undefined ? undefined : styles[asset.size], uniqueClassName)}>
        {sizesToUse.map(({ width, minWidth, ratio }) => {
          const imageAsset = withoutHotspot
            ? imageUrlFor(asset).auto('format').quality(100).fit('crop')
            : imageUrlFor(asset).auto('format').quality(100).fit('crop').width(width);

          const imageUrlWithMaybeHeight = ratio ? imageAsset.height(Math.round(width / ratio)) : imageAsset;
          const imageUrlWithMaybeEntropy = asset.hotspot
            ? imageUrlWithMaybeHeight
            : imageUrlWithMaybeHeight.crop('entropy');

          // We need to check if there is an asset in the source
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (!imageUrlWithMaybeEntropy?.options?.source?.asset) {
            return;
          }

          return (
            <Fragment key={`${width}-${minWidth}`}>
              <source
                srcSet={`${imageUrlWithMaybeEntropy.dpr(2).url()} ${width}w`}
                sizes={`(min-width: ${minWidth}px) ${width}px`}
                media={`(min-width: ${minWidth}px) and (-webkit-min-device-pixel-ratio: 1.25), (min-width: ${minWidth}px) and (min-resolution: 120dpi)`}
              />
              <source
                srcSet={`${imageUrlWithMaybeEntropy.url()} ${width}w`}
                sizes={`(min-width: ${minWidth}px) ${width}px`}
                media={`(min-width: ${minWidth}px)`}
              />
            </Fragment>
          );
        })}
        <img draggable={draggable} title={asset.title} alt={asset.alt} loading={loading} />
      </picture>
    </Fragment>
  );
};
