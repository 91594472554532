import React, { useMemo } from 'react';
import dynamic from 'next/dynamic';
import {
  HoldingButtonProperties,
  HeilijgersButtonProperties,
  NovaFormButtonProperties,
  VanDeKlokButtonProperties,
  VanErkButtonProperties,
  HeilijgersButtonLinkProperties,
  HoldingButtonLinkProperties,
  NovaFormButtonLinkProperties,
  VanDeKlokButtonLinkProperties,
  VanErkButtonLinkProperties,
  ButtonProperties,
  ButtonLinkProperties,
} from './ButtonInterfaces';
import { useSiteInfo } from '../SiteInfoProvider';
import styles from './Button.module.css';
import { Icon } from '@klokgroep/shared-components/src/Icons';

const ssr = true;

// -------------------------------------------------------DYNAMIC IMPORTS-------------------------------------------------------

const DynamicHoldingButton = dynamic<HoldingButtonProperties>(
  () => import('./HoldingButton').then((module) => module.HoldingButton),
  { ssr }
);
const DynamicHoldingButtonLink = dynamic<HoldingButtonLinkProperties>(
  () => import('./HoldingButtonLink').then((module) => module.HoldingButtonLink),
  { ssr }
);

const DynamicHeilijgersButton = dynamic<HeilijgersButtonProperties>(
  () => import('./HeilijgersButton').then((module) => module.HeilijgersButton),
  { ssr }
);
const DynamicHeilijgersButtonLink = dynamic<HeilijgersButtonLinkProperties>(
  () => import('./HeilijgersButtonLink').then((module) => module.HeilijgersButtonLink),
  { ssr }
);

const DynamicNovaformButton = dynamic<NovaFormButtonProperties>(
  () => import('./NovaformButton').then((module) => module.NovaformButton),
  { ssr }
);
const DynamicNovaformButtonLink = dynamic<NovaFormButtonLinkProperties>(
  () => import('./NovaformButtonLink').then((module) => module.NovaformButtonLink),
  { ssr }
);

const DynamicVanDeKlokButton = dynamic<VanDeKlokButtonProperties>(
  () => import('./VanDeKlokButton').then((module) => module.VanDeKlokButton),
  { ssr }
);
const DynamicVanDeKlokButtonLink = dynamic<VanDeKlokButtonLinkProperties>(
  () => import('./VanDeKlokButtonLink').then((module) => module.VanDeKlokButtonLink),
  { ssr }
);

const DynamicVanErkButton = dynamic<VanErkButtonProperties>(
  () => import('./VanErkButton').then((module) => module.VanErkButton),
  { ssr }
);
const DynamicVanErkButtonLink = dynamic<VanErkButtonLinkProperties>(
  () => import('./VanErkButtonLink').then((module) => module.VanErkButtonLink),
  { ssr }
);

// -------------------------------------------------------EXPORT COMPONENTS-------------------------------------------------------

export const Button = ({ icon, children, ...rest }: ButtonProperties) => {
  const { theme } = useSiteInfo();

  const ButtonToRender = useMemo(() => {
    if (theme === 'heilijgers') return DynamicHeilijgersButton as React.ElementType;
    if (theme === 'novaform') return DynamicNovaformButton as React.ElementType;
    if (theme === 'vandeklok') return DynamicVanDeKlokButton as React.ElementType;
    if (theme === 'vanerk') return DynamicVanErkButton as React.ElementType;
    return DynamicHoldingButton as React.ElementType;
  }, [theme]);
  return (
    <ButtonToRender {...rest}>
      <div className={styles.globalButtonContainer}>
        {children}
        {!!icon && <Icon type={icon} />}
      </div>
    </ButtonToRender>
  );
};

export const ButtonLink = ({ icon, children, ...rest }: ButtonLinkProperties) => {
  const { theme } = useSiteInfo();

  const IconToRender = useMemo(() => Icon[icon as keyof typeof Icon], [icon]);

  const ButtonLinkToRender = useMemo(() => {
    if (theme === 'heilijgers') return DynamicHeilijgersButtonLink as React.ElementType;
    if (theme === 'novaform') return DynamicNovaformButtonLink as React.ElementType;
    if (theme === 'vandeklok') return DynamicVanDeKlokButtonLink as React.ElementType;
    if (theme === 'vanerk') return DynamicVanErkButtonLink as React.ElementType;
    return DynamicHoldingButtonLink as React.ElementType;
  }, [theme]);

  return (
    <ButtonLinkToRender {...rest}>
      <div className={styles.globalButtonContainer}>
        {children}
        {!!icon && IconToRender}
      </div>
    </ButtonLinkToRender>
  );
};
