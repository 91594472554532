import { getClient } from '../sanity.server';
import { groq } from 'next-sanity';
import { SeoQuery, createSeoQuery } from './helpers/seoQuery';
import { SiteIdType } from '../sites';
import { JobHeroBlock, jobHeroQuery } from '../queries/blocks/jobHero';
import { getContentModulesQueries, MappedContentModules } from '../queries/blocks/contentModules';
import { PageHeroBlock, pageHeroQuery } from '../queries/blocks/pageHero';
import { getJobContentModulesQueries, MappedJobContentModules } from '../queries/blocks/jobContentModules';
import { createCorporatePageQuery } from '../queries/corporatePage';
import { PageParent } from '../queries/page';
import {
  RelatedContentBlockProperties,
  getRelatedContentItemsQueryStringForRoot,
} from '../queries/blocks/relatedContent';
import { SanityImageType } from '../queries/helpers';
import { PreviewImageType, mediaItemsQuery } from '../queries/helpers/mediaItems';
import { contactPersonQuery } from '../queries/helpers/contactPersonQuery';

export interface GetJobsResponse
  extends Omit<Sanity.Schema.SharedJob, 'categories' | 'locations' | 'siteId' | 'jobHero' | 'status' | 'intendedFor'> {
  _createdAt: string;
  jobHero: JobHeroBlock;
  siteId: SiteIdType;
  intendedFor?: SiteIdType;
  previewImage?: PreviewImageType;
  locations?: Sanity.Schema.JobLocationTaxonomy[];
  categories?: Sanity.Schema.JobCategoryTaxonomy[];
  status?: { label?: string; slug?: { current?: string } };
}

type GetCorporateJobSlugsResponse = {
  slug: string;
  siteId?: SiteIdType;
  parent: { _ref: string };
}[];

export const getJobSlugs = async (preview = false, filterNoIndex?: boolean) =>
  getClient(preview).fetch<
    { slug: string; siteId: SiteIdType; parent?: { _ref: string } }[]
  >(groq`*[(_type == 'shared-job' || parent._ref == 'holding-jobsOverviewPage') && defined(slug.current)${
    filterNoIndex ? ` && seo.noIndex != true && parent->seo.noIndex != true` : ''
  }]{
    "slug": lower(slug.current),
    "siteId": select(
        defined( siteId ) => siteId,
        parent._ref == 'holding-jobsOverviewPage' => 'holding',
      ),
    parent{ _ref }
  }`);

export const getCorporateJobSlugsWithSiteIds = async (preview = false, filterNoIndex?: boolean) =>
  getClient(preview)
    .fetch<GetCorporateJobSlugsResponse>(groq`*[(_type == 'shared-job' || parent._ref match 'corporate-jobsOverviewPage*') && defined(slug.current) && defined(siteId)${
    filterNoIndex ? ` && seo.noIndex != true && parent->seo.noIndex != true` : ''
  }]{
  "slug": lower(slug.current),
  siteId,
  parent{ _ref }
}`);

export interface GetJobForSlugResponse {
  _createdAt?: string;
  _id: Sanity.Schema.HoldingPage['_id'];
  applicationUrl?: string;
  categories?: Sanity.Schema.JobCategoryTaxonomy[];
  datePublished?: string;
  shareFooterColor?: 'white' | 'lightGray';
  footerColor?: 'white' | 'lightGray';
  jobContentModules?: MappedJobContentModules;
  jobHero?: JobHeroBlock;
  locations?: Sanity.Schema.JobLocationTaxonomy[];
  pageType?: 'job' | 'page';
  relatedContent?: RelatedContentBlockProperties;
  salaryMin?: number;
  salaryMax?: number;
  seo?: SeoQuery;
  siteId?: SiteIdType;
  slug?: { current: string };
  workingHoursMin?: number;
  workingHoursMax?: number;
  contactPerson?: Omit<Sanity.Schema.SharedContactPerson, 'image'> & {
    image?: SanityImageType;
  };
  intendedFor?: SiteIdType;
}

export interface GetJobPageForSlugsResponse {
  _id: Sanity.Schema.HoldingPage['_id'];
  contentModules: MappedContentModules;
  pageHero?: PageHeroBlock;
  shareFooterColor?: 'white' | 'lightGray';
  footerColor?: 'white' | 'lightGray';
  seo?: SeoQuery;
  slug: { current: string };
  parent?: PageParent;
  pageType?: 'job' | 'page';
  siteId?: SiteIdType;
  contactPerson?: Omit<Sanity.Schema.SharedContactPerson, 'image'> & {
    image?: SanityImageType;
  };
}

export const createJobQuery = (siteId: SiteIdType, preview?: boolean) => groq`*[(_type == 'shared-job')  ${
  siteId && siteId !== 'holding' ? `&& siteId == $siteId` : ``
} && lower(slug.current) == $slug] | order(_updatedAt desc)[0]  
      {
  _id,
  _type,
  "datePublished": select(
    defined( datePublished ) => datePublished,
    _updatedAt
  ),
  _createdAt,
  ${createSeoQuery(siteId)},
  ${jobHeroQuery},
  applicationUrl,
  salaryMin,
  salaryMax,
  workingHoursMin,
  workingHoursMax,
  locations[]->{slug,name},
  siteId,
  categories[]->{slug,name},
  slug,
  shareFooterColor,
  footerColor,
  jobContentModules[] { ...select( ${getJobContentModulesQueries()} )}, 
  relatedContent {
    _type,
    _key,
   title,
   backgroundColor,
   ${preview == true ? undefined : getRelatedContentItemsQueryStringForRoot(siteId)},
  },
  ${contactPersonQuery},
  intendedFor,
}`;

export const getJobForSlug = async (slug: string, siteId: SiteIdType, preview = false) =>
  getClient(preview).fetch<GetJobForSlugResponse>(createJobQuery(siteId, preview), {
    slug,
    siteId,
  });

export const createJobItemFields = (withSiteId?: boolean) => groq`
  "datePublished": select(
    defined( datePublished ) => datePublished,
    _updatedAt
  ),
  _createdAt,
  jobHero {
    ...,
    ${mediaItemsQuery}
  },
  status->{slug,label},
  salaryMin,
  salaryMax,
  workingHoursMin,
  workingHoursMax,
  locations[]->{slug,name},
  ${withSiteId ? 'siteId,' : ''}
  categories[]->{slug,name},
  slug,
  siteId,
  intendedFor
`;

const createGetJobsQuery = (siteIds?: string[], showOnHolding?: boolean, amount?: number) => {
  const typeFilter = `_type == 'shared-job' ${siteIds ? `&& siteId in $siteIds` : ``}${
    showOnHolding ? `&& (showOnHolding == ${showOnHolding} || siteId == "holding")` : ``
  }`;
  return groq`*[${typeFilter}] {
    ${createJobItemFields(showOnHolding)}
  } | order(datePublished desc)${amount ? `[0...${amount}]` : ''}`;
};

interface GetJobOptions {
  amount?: number;
  siteIds?: string[];
  showOnHolding?: boolean;
}

export const getJobs = async (preview = false, options: GetJobOptions = {}, siteId?: SiteIdType) =>
  getClient(preview).fetch<GetJobsResponse[]>(
    createGetJobsQuery(options?.siteIds, options?.showOnHolding, options?.amount),
    { ...options, siteId }
  );

export const holdingGeneralJobPageQuery = (
  siteId: SiteIdType,
  preview?: boolean
) => groq`*[_type == 'holding-page' && lower(slug.current) == lower($slug)] | order(_updatedAt desc)[0]  {
    _id,
    _type,
    footerColor,
    shareFooterColor,
    slug,
    ${createSeoQuery()},
    ${pageHeroQuery},
    contentModules[] { ...select( ${getContentModulesQueries(siteId, preview)} ), },

  }`;

export const getGeneralJobPageForSlug = async (slug: string, siteId: SiteIdType, preview = false) => {
  const result = await getClient(preview).fetch<GetJobPageForSlugsResponse>(
    !siteId || siteId === 'holding'
      ? holdingGeneralJobPageQuery(siteId, preview)
      : createCorporatePageQuery(siteId, preview, 'job'),
    { slug, siteId: siteId }
  );

  return result;
};
