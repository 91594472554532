import { getClient } from '../sanity.server';
import { groq } from 'next-sanity';
import { PageHeroBlock, pageHeroQuery } from './blocks/pageHero';
import { SeoQuery, createSeoQuery } from './helpers/seoQuery';
import { SiteIdType } from '../sites';
import { SanityImageType } from './helpers';

export interface HoldingNewsPageResponse extends Omit<Sanity.Schema.HoldingNewsPage, 'seo' | 'pageHero'> {
  _id: string;
  seo: SeoQuery;
  pageHero: PageHeroBlock;
  newsletterSubscriptionBlock: {
    title?: string;
    subtitle?: string;
    newsletterBackgroundImage?: SanityImageType;
    buttonLabel?: string;
  };
}

export const createNewsPageQuery = (siteId?: SiteIdType) => {
  const typeFilter =
    siteId === 'holding' || !siteId
      ? `_type == 'holding-newsPage'`
      : `_type match 'corporate-newsPage' && siteId == '${siteId}'`;

  return groq`*[${typeFilter}] | order(_updatedAt desc)[0] {
  ${createSeoQuery(siteId)},
  ${pageHeroQuery},
  newsletterSubscriptionBlock
}`;
};

export const getNewsPage = async (preview = false, siteId?: SiteIdType) =>
  getClient(preview).fetch<HoldingNewsPageResponse>(createNewsPageQuery(siteId));
