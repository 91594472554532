import { groq } from 'next-sanity';
import { SiteIdType } from '../../sites';
import { SocialsQuery, createSocialsQuery } from './socialsQuery';
import { getSitewideDataFilter } from '../../utils';

export type SitewideSeoQuery = Sanity.Schema.HoldingSettings['seoData'] & {
  organisationLogo?: {
    url?: string;
    width?: number;
    height?: number;
  };
};

export const createSitewideSeoQuery = (siteId?: SiteIdType) => {
  const siteIdFilter =
    siteId === 'holding' || siteId === undefined
      ? `_type == 'holding-settings'`
      : `_type == 'corporate-settings' && siteId == '${siteId}'`;

  const filter = getSitewideDataFilter(siteIdFilter);

  return groq`
"sitewideSeo": *${filter}[0].seoData {
  ...,
  "organisationLogo": organisationLogo {
     "url": asset->url,
     "width": asset->metadata.dimensions.width,
     "height": asset->metadata.dimensions.height,
  },
}`;
};

type PageSeoQuery = Sanity.Schema.HoldingHomepage['seo'] & {
  image?: string;
};

const pageSeoQuery = groq`
"pageSeo": seo {
  ...,
  "image": image.asset->url,
}`;

export type SeoQuery = {
  pageSeo?: PageSeoQuery;
  sitewideSeo?: SitewideSeoQuery;
  socials?: SocialsQuery;
};

export const createSeoQuery = (siteId?: SiteIdType) => groq`
"seo": {
  ${pageSeoQuery},
  ${createSitewideSeoQuery(siteId)},
  ${createSocialsQuery(siteId)},
}`;
