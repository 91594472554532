import { groq } from 'next-sanity';
import { imageQuery } from '../helpers/image';
import { contentLinkQuery } from '../helpers/link';

const NAME = 'imageAndFoldouts';

export const imageAndFoldoutsQuery = groq`
  _type,
  _key,
  title,
  content[] ${contentLinkQuery},
  backgroundColor,
  reversed,
  items[] {
    ...,
    content[] ${contentLinkQuery},
  },
  video,
  ${imageQuery},
`;

export const imageAndFoldoutsModuleQuery = groq`
  _type == '${NAME}' => {
    ${imageAndFoldoutsQuery}
  },
`;

export type ImageAndFoldoutsBlock = Sanity.Keyed<Sanity.Schema.ImageAndFoldouts>;
