import { contentLinkQuery } from './helpers/link';
import { getClient } from '../sanity.server';
import { groq } from 'next-sanity';
import { SeoQuery, createSeoQuery } from './helpers/seoQuery';

export interface HoldingProjectsOverviewResponse extends Omit<Sanity.Schema.HoldingProjectsOverviewPage, 'seo'> {
  seo: SeoQuery;
}

export const createHoldingProjectsOverviewPageQuery =
  () => groq`*[_type == 'holding-projectsOverviewPage'] | order(_updatedAt desc)[0] {
  ${createSeoQuery()},
  intro[] ${contentLinkQuery},
  order,
}`;

export const getHoldingProjectsOverviewPage = async (preview = false) =>
  getClient(preview).fetch<HoldingProjectsOverviewResponse>(createHoldingProjectsOverviewPageQuery());
