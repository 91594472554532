'use client';

import Cookies from 'js-cookie';

const COOKIE_CONSENT_KEY = 'COOKIE_CONSENT';

export const COOKIE_LEVELS = {
  ['NECESSARY' as const]: 1,
  ['ANALYTICS' as const]: 2,
  ['MARKETING' as const]: 3,
};

const getBaseDomain = () => {
  const hostname = window.location.hostname;
  const parts = hostname.split('.').filter(Boolean);

  // Handle cases like 'localhost' or IP addresses
  if (parts.length <= 2) {
    return hostname;
  }

  // Return the last two parts as the base domain
  return parts.slice(-2).join('.');
};

export const getCookiesConsent = () => {
  const valueAsString = Cookies.get(COOKIE_CONSENT_KEY);

  if (!valueAsString) {
    return;
  }

  return Number.parseInt(valueAsString, 10);
};

export const setCookiesConsent = (level: number) => {
  const baseDomain = getBaseDomain();
  Cookies.set(COOKIE_CONSENT_KEY, level.toString(), {
    expires: 365,
    domain: baseDomain,
    path: '/',
  });
};
