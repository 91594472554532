import { groq } from 'next-sanity';
import { LinkType } from '../../queries/helpers';
import { contentLinkQuery, linkQueryBody } from '../../queries/helpers/link';

export const NAME = 'textWithContentCards';

export const textWithContentCardsQuery = groq`
  _type == '${NAME}' => {
    _type,
    title,
    text[] ${contentLinkQuery},
    button {
      ${linkQueryBody}
    },
    isBoxed,
    contentCards[] {
      ...,
      link {
        ${linkQueryBody}
      }
    },
    settings {
      gridColumns,
      backgroundColor,
      imageCrop,
    }
  },
`;

export interface ContentCardBoxedBlock extends Omit<Sanity.Keyed<Sanity.Schema.ContentCard>, 'link'> {
  link?: LinkType;
}

export type TextWithContentCardsBlock = {
  _type: 'textWithContentCards';
  title?: string;
  text?: Array<Sanity.Keyed<Sanity.Block>>;
  isBoxed?: boolean;
  contentCards: ContentCardBoxedBlock[];
  settings?: {
    gridColumns?: 1 | 2 | 3 | 4;
    backgroundColor?: 'blue' | 'gray' | 'lightGray' | 'white';
    imageCrop?: 'square' | 'landscape';
  };
  button?: {
    label?: string;
    href?: { _type: Sanity.Schema.Document['_type']; slug?: { _type: 'slug'; current?: string } };
    targetBlank?: boolean;
    externalLink?: string;
    type?: 'external' | 'reference';
  };
};
