import { getClient } from '../sanity.server';
import { groq } from 'next-sanity';
import { LinkQuery, linkQueryBody } from './helpers/link';
import { PageHeroBlock, pageHeroQuery } from './blocks/pageHero';
import { SeoQuery, createSeoQuery } from './helpers/seoQuery';
import { SiteIdType } from '../sites';

export interface JobOverviewPageResponse
  extends Omit<Sanity.Schema.CorporateJobsOverviewPage, 'seo' | 'pageHero' | 'siteId' | 'openApplicationLink'> {
  _id: string;
  seo: SeoQuery;
  pageHero: PageHeroBlock;
  siteId?: SiteIdType;
  subtitle?: string;
  openApplicationLink?: LinkQuery;
}

export const holdingJobOverviewPageQuery = groq`*[_type == 'holding-jobsOverviewPage'] | order(_updatedAt desc)[0] {
  ${createSeoQuery()},
  ${pageHeroQuery},
  subtitle,
  openApplicationLink {
    ${linkQueryBody}
  }
}`;

export const createCorporateJobOverviewPageQuery = (siteId?: SiteIdType) => {
  const typeFilter = `_type == 'corporate-jobsOverviewPage' ${siteId ? `&& siteId == $siteId` : ``}`;
  return groq`*[${typeFilter}] | order(_updatedAt desc)[0] {
  ${createSeoQuery(siteId)},
  ${pageHeroQuery},
  subtitle,
  openApplicationLink {
    ${linkQueryBody}
  }
}`;
};

export const getJobsOverviewPage = async (preview = false, siteId?: SiteIdType) =>
  getClient(preview).fetch<JobOverviewPageResponse>(
    !siteId || siteId === 'holding' ? holdingJobOverviewPageQuery : createCorporateJobOverviewPageQuery(siteId),
    { siteId: siteId }
  );
