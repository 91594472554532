import { Icon } from '@klokgroep/shared-components/src/Icons';
import { useCallback, useState, useMemo } from 'react';
import { useRouter } from 'next/router';
import cx from 'classnames';
import Link from 'next/link';
import styles from './LanguageToggle.module.css';

interface Properties {
  locales: { href?: string; locale?: string; title?: string }[];
}

const LOCALE_ICON_MAP: Record<string, string> = {
  nl: 'netherlands',
  pl: 'poland',
  de: 'germany',
};

export const LanguageToggle = ({ locales }: Properties) => {
  const router = useRouter();

  const [isOpen, setIsOpen] = useState(false);

  const activeHref = useMemo(
    () => locales?.find(({ href }) => href?.toLowerCase().includes(router.asPath.toLowerCase()))?.href,
    [locales, router.asPath]
  );

  const toggleIsOpen = useCallback(() => {
    setIsOpen((previous) => !previous);
  }, []);

  return (
    <div className={styles.container}>
      <button type="button" className={styles.button} onClick={toggleIsOpen}>
        <Icon type="globe" />
        <Icon type="caretDown" />
      </button>
      <div className={styles.listContainer}>
        <ul className={cx(styles.languageList, { [styles.isOpen]: isOpen })}>
          {locales?.map(({ href = '#', title, locale = 'nl' }) => (
            <li key={href} className={cx(styles.languageItem, { [styles.isActive]: href === activeHref })}>
              <Link className={styles.languageButton} href={href}>
                {title} <Icon type={LOCALE_ICON_MAP[locale] as keyof typeof Icon} />
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
