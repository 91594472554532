import { getClient } from '../sanity.server';
import { groq } from 'next-sanity';
import { MappedContentModules, getContentModulesQueries } from './blocks/contentModules';
import { PageHeroBlock, pageHeroQuery } from './blocks/pageHero';
import { SeoQuery, createSeoQuery } from './helpers/seoQuery';
import { ImagesWithLinksBlock, imagesWithLinksQuery } from './blocks/imagesWithLinks';
import { SiteIdType } from '@klokgroep/sanity/src/sites';

export const getThemeSlugs = async (preview = false, filterNoIndex?: boolean) =>
  getClient(preview).fetch<string[]>(
    groq`*[_type == 'holding-theme' && defined(slug.current)${
      filterNoIndex ? ` && seo.noIndex != true && parent->seo.noIndex != true` : ''
    }].slug.current`
  );

export interface GetThemeForSlugResponse {
  _id: Sanity.Schema.HoldingPage['_id'];
  seo: SeoQuery;
  pageHero: PageHeroBlock;
  contentModules: MappedContentModules;
  imagesWithLinks: ImagesWithLinksBlock;
}

export const holdingThemeQuery = (
  siteId: SiteIdType,
  preview?: boolean
) => groq`*[_type == 'holding-theme' && lower(slug.current) == lower($slug)] | order(_updatedAt desc)[0] {
  _id,
  ${createSeoQuery()},
  ${pageHeroQuery},
  contentModules[] { ...select( ${getContentModulesQueries(siteId, preview)} ), },
  imagesWithLinks { ${imagesWithLinksQuery} }
}`;

export const getThemeForSlug = async (siteId: SiteIdType, slug: string, preview = false) =>
  getClient(preview).fetch<GetThemeForSlugResponse>(holdingThemeQuery(siteId, preview), { slug });

export interface GetRelatedThemesResponse {
  href?: { _type: Sanity.Schema.Document['_type']; slug: { _type: 'slug'; current: string } };
  image: { alt: string; src: string };
  label?: string;
  title: string;
}
const relatedThemesQuery = groq`*[_type == 'holding-theme' && slug.current != $slugToExclude] | order(_updatedAt desc) {
  "label": pageHero.title, 
  "title": pageHero.text, 
  "image": pageHero.image,
  "href": {
    "_type": _type,
    "slug": slug
  }    
}`;

export const getRelatedThemes = async (slugToExclude: string, preview = false) =>
  getClient(preview).fetch<GetRelatedThemesResponse[]>(relatedThemesQuery, { slugToExclude });
