import { BlockContainerWithBackgroundProperties } from '@klokgroep/shared-components/src/BlockContainerWithBackground';
import { groq } from 'next-sanity';

const NAME = 'blockWithColumnsAndIcons';

export const blockWithColumnsAndIconsQuery = groq`
  _type == '${NAME}' => {
    _type,
    _key,
    columns[] {description, icon, title, _key},
    backgroundColor,
  },
`;

export interface ColumnsAndIconsBlock extends Omit<Sanity.Keyed<Sanity.Schema.BlockWithColumnsAndIcons>, 'columns'> {
  columns: {
    description?: string;
    icon?: string;
    title?: string;
    _key: string;
    backgroundColor?: BlockContainerWithBackgroundProperties['backgroundColor'];
  }[];
}
