import { groq } from 'next-sanity';
import { imageQuery } from '../../queries/helpers/image';
import { contentLinkQuery, linkQueryBody } from '../../queries/helpers/link';

export const NAME = 'twoColumns';

export const twoColumnsQuery = groq`
  _type == '${NAME}' => {
    _type,
    _key,
    backgroundColor,
    columnOne {
      content[] ${contentLinkQuery},
      ${imageQuery},
      button {
        ${linkQueryBody}
      }
    },
    columnTwo {
      content[] ${contentLinkQuery},
      ${imageQuery},
      button {
        ${linkQueryBody}
      }
    }
  },
`;

interface ColumnProperties {
  content?: Array<Sanity.Keyed<Sanity.Block>>;
  image?: {
    asset: Sanity.Asset;
    crop?: Sanity.ImageCrop;
    size?: 'portrait' | 'landscape' | 'square';
    hotspot?: Sanity.ImageHotspot;
    title?: string;
    alt?: string;
  };
  button?: TwoColumnSingleButtonProperties;
}

export interface TwoColumnSingleButtonProperties {
  label?: string;
  href?: { _type: Sanity.Schema.Document['_type']; slug?: { _type: 'slug'; current?: string } };
  targetBlank?: boolean;
  externalLink?: string;
  type?: 'external' | 'reference';
}

export interface TwoColumnsBlock {
  _type: 'twoColumns';
  _key: string;
  backgroundColor?: 'blue' | 'gray' | 'lightGray' | 'white';
  columnOne: ColumnProperties;
  columnTwo: ColumnProperties;
}
