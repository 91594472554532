import { CallToActionBlock, callToActionQuery } from './callToAction';
import { CarouselBlock, carouselQuery } from './carousel';
import { ColumnsAndIconsBlock, blockWithColumnsAndIconsQuery } from '../../queries/blocks/blockWithColumnsAndIcons';
import { ImageAndButtonBlock, imageAndButtonQuery } from './imageAndButton';
import { ImageAndFoldoutsBlock, imageAndFoldoutsModuleQuery } from './imageAndFoldouts';
import { ImageAndLinksBlock, imageAndLinksQuery } from './imageAndLinks';
import { imageAndNewsletterSignupQuery } from '../../queries/blocks/imageAndNewsletterSignup';
import { ImageAndTextBlock, imageAndTextQuery } from './imageAndText';
import { ImagesWithLinksBlock, imagesWithLinksModuleQuery } from './imagesWithLinks';
import { ItemsWithIconsBlock, itemWithIconsQuery } from './itemsWithIcons';
import { jobApplicationProcessQuery } from './jobApplicationProcess';
import { MediaBlock, mediaQuery } from '../../corporateQueries/blocks/mediaBlock';
import { PortraitSliderBlock, portraitSliderQuery } from './portraitSlider';
import { RichTextBlock, richTextQuery } from './richText';
import { TestimonialBlock, testimonialQuery } from './testimonial';
import { TextAndFoldoutsBlock, textAndFoldoutsModuleQuery } from './textAndFoldouts';
import { TitleVideoBlock, titleVideoQuery } from './titleVideo';
import { RelatedContentBlockProperties, getRelatedContentQuery } from '../../queries/blocks/relatedContent';
import { verticalCarouselWithTitleQuery, VerticalCarouselBlock } from './verticalCarouselWithTitles';
import { formBlockQuery, FormBlockProperties } from './formBlock';
import { PeopleGridBlock, peopleGridQuery } from '../../queries/blocks/peopleGrid';
import { EmbedBlockProperties, embedQuery } from '../../queries/blocks/embed';
import { SiteIdType } from '@klokgroep/sanity/src/sites';

export const getContentModulesQueries = (siteId: SiteIdType, preview?: boolean) =>
  [
    mediaQuery,
    blockWithColumnsAndIconsQuery,
    callToActionQuery,
    carouselQuery,
    embedQuery,
    formBlockQuery,
    imageAndButtonQuery,
    imageAndFoldoutsModuleQuery,
    imageAndLinksQuery,
    imageAndNewsletterSignupQuery,
    imageAndTextQuery,
    imagesWithLinksModuleQuery,
    itemWithIconsQuery,
    jobApplicationProcessQuery,
    peopleGridQuery,
    portraitSliderQuery,
    richTextQuery,
    testimonialQuery,
    textAndFoldoutsModuleQuery,
    titleVideoQuery,
    verticalCarouselWithTitleQuery,
    getRelatedContentQuery(siteId, preview),
  ].join('');

export type MappedContentModules = Array<
  | ColumnsAndIconsBlock
  | CallToActionBlock
  | CarouselBlock
  | EmbedBlockProperties
  | FormBlockProperties
  | ImageAndButtonBlock
  | ImageAndFoldoutsBlock
  | ImageAndLinksBlock
  | ImageAndTextBlock
  | ImagesWithLinksBlock
  | ItemsWithIconsBlock
  | RelatedContentBlockProperties
  | PeopleGridBlock
  | PortraitSliderBlock
  | RichTextBlock
  | TestimonialBlock
  | TextAndFoldoutsBlock
  | TitleVideoBlock
  | MediaBlock
  | VerticalCarouselBlock
>;
