import { groq } from 'next-sanity';
import { linkQueryBody } from '../../queries/helpers/link';
import { MediaItemsType, PreviewImageType, mediaItemsQuery } from '../../queries/helpers/mediaItems';

export const jobHeroQuery = groq`
  jobHero {
    title,
    intro,
    ${mediaItemsQuery},
    link { ${linkQueryBody} }
  }`;

export interface JobHeroBlock extends Omit<Sanity.Keyed<Sanity.Schema.JobHero>, 'link' | 'mediaItems'> {
  link?: { href?: string; label?: string; targetBlank?: boolean; type?: 'reference' | 'external' };
  mediaItems?: MediaItemsType;
  previewImage?: PreviewImageType;
}
