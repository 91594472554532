import { MediaBlock, mediaQuery } from '../../corporateQueries';
import { ColumnsAndIconsBlock, blockWithColumnsAndIconsQuery } from './blockWithColumnsAndIcons';
import { ContentBlock, contentQuery } from './content';
import { TitleVideoBlock, titleVideoQuery } from './titleVideo';
import { JobApplicationProcessBlock, jobApplicationProcessQuery } from '../../queries/blocks/jobApplicationProcess';
import { CallToActionBlock, callToActionQuery } from '../../queries/blocks/callToAction';

export const getJobContentModulesQueries = () =>
  [
    contentQuery,
    titleVideoQuery,
    mediaQuery,
    blockWithColumnsAndIconsQuery,
    jobApplicationProcessQuery,
    callToActionQuery,
  ].join('');

export type MappedJobContentModules = Array<
  ContentBlock | TitleVideoBlock | ColumnsAndIconsBlock | JobApplicationProcessBlock | MediaBlock | CallToActionBlock
>;
