import { groq } from 'next-sanity';
import { contentLinkQuery, linkQueryBody } from '../../queries/helpers/link';

export const NAME = 'titleMultipleRichText';

export const titleMultipleRichTextQuery = groq`
  _type == '${NAME}' => {
    _type,
    _key,
    backgroundColor,
    title,
    subText[] ${contentLinkQuery},
    button {
      ${linkQueryBody}
    },
    richTextModules[] {
      ...,
      content[] ${contentLinkQuery}
    },
  },
`;
export interface TitleMultipleRichTextBlock extends Omit<Sanity.Keyed<Sanity.Schema.TitleMultipleRichText>, 'button'> {
  button?: {
    label?: string;
    href?: { _type: Sanity.Schema.Document['_type']; slug?: { _type: 'slug'; current?: string } };
    targetBlank?: boolean;
    externalLink?: string;
    type?: 'external' | 'reference';
  };
}
