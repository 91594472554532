import { SITES, translatePagesPathToPathname } from '@klokgroep/sanity';
import { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';

const SESSION_STORAGE_ROUTER_KEY = '@KLOKGROEP/ROUTER_PATHS';

// TODO later: share the referrer
const KLOKGROEP_REFERRER = 'klokgroep';

const HOLDING_SITE = SITES.find(({ type }) => type === 'holding');
const HOLDING_URL = process.env.NEXT_PUBLIC_IS_ACCEPTANCE
  ? HOLDING_SITE!.acceptanceHostname
  : process.env.NEXT_PUBLIC_IS_DEVELOPMENT
  ? HOLDING_SITE!.previewHostname
  : process.env.NODE_ENV === 'production'
  ? HOLDING_SITE!.productionHostname
  : HOLDING_SITE!.developmentHostname;

export const useSaveRouterPathToSessionStorageOnRouterPathChange = () => {
  const router = useRouter();

  useEffect(() => {
    saveRouterPathToSessionStorage(router.asPath);
  }, [router.asPath]);

  return null;
};

export const useBackLinkHref = (defaultHref: string) => {
  const { locale } = useSiteInfo();
  const router = useRouter();

  const href = useMemo(() => {
    const translatedDefaultHref = translatePagesPathToPathname(defaultHref, locale);
    const { referrer, ...rest } = router.query || {};

    if (typeof window === 'undefined') return translatedDefaultHref;

    if (referrer === KLOKGROEP_REFERRER) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const queryString = new URLSearchParams(rest).toString();
      return `${HOLDING_URL}${translatedDefaultHref}?${queryString}`;
    } else {
      const previousPath = getLatestPathFromSessionStorageThatIsNotMyCurrentPath(router.asPath);
      return !previousPath || !previousPath.includes(translatedDefaultHref) ? translatedDefaultHref : previousPath;
    }
    // !important: Keep router.asPath here to make sure the hook reruns when the path changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultHref, router.query, router.asPath]);

  return href;
};

const getLatestPathFromSessionStorageThatIsNotMyCurrentPath = (currentPath: string) => {
  const sessionStorage = window?.sessionStorage;

  if (!sessionStorage) return;

  const sessionPathsAsString = sessionStorage.getItem(SESSION_STORAGE_ROUTER_KEY);
  const sessionPaths: string[] = sessionPathsAsString ? JSON.parse(sessionPathsAsString) : [];

  if (sessionPaths.length === 0) return;

  const currentPathIndex = sessionPaths.findIndex((sessionPath) => sessionPath.includes(currentPath));
  const previousPathIndex = currentPathIndex === -1 ? sessionPaths.length - 1 : currentPathIndex - 1;

  const previousPath = sessionPaths.at(previousPathIndex);
  const currentHost = getHostWithProtocol();
  const previousPathWithoutCurrentHost = previousPath?.replace(currentHost, '');

  return previousPathWithoutCurrentHost;
};

const saveRouterPathToSessionStorage = (path: string) => {
  const sessionStorage = window?.sessionStorage;

  if (!sessionStorage) return;

  const sessionPathsAsString = sessionStorage.getItem(SESSION_STORAGE_ROUTER_KEY);
  const sessionPaths = sessionPathsAsString ? JSON.parse(sessionPathsAsString) : [];
  const previousPath = sessionPaths.at(-1);

  const pathWithHost = `${getHostWithProtocol()}${path}`;

  if (previousPath === pathWithHost) return sessionPaths;

  const newPaths = [previousPath, pathWithHost].filter(Boolean);
  sessionStorage.setItem(SESSION_STORAGE_ROUTER_KEY, JSON.stringify(newPaths));

  return newPaths;
};

const getHostWithProtocol = () => `${window?.location?.protocol}//${window?.location?.host}`;
