import { ButtonLink } from '@klokgroep/shared-components/src/Button';
import { MaxWidth } from '@klokgroep/shared-components/src/MaxWidth';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import { ToggleButton } from '@klokgroep/shared-components/src/ToggleButton';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import styles from './PreviewBar.module.css';
import cx from 'classnames';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';

export const PreviewBar = () => {
  const [showPreviewBar, setShowPreviewBar] = useState(true);
  const router = useRouter();
  const onHidePreviewBar = useCallback(() => {
    setShowPreviewBar((previousState) => !previousState);
  }, []);

  const { theme } = useSiteInfo();

  const targetUrl =
    !theme || theme === 'holding'
      ? `/api/exit-preview?path=${router.asPath}`
      : `/api/exit-preview?path=${router.asPath}&siteId=${theme}`;

  return (
    <div className={cx(styles.container, { [styles.containerActive]: showPreviewBar })}>
      <MaxWidth>
        {!!showPreviewBar && (
          <div className={styles.content}>
            <RichText>
              <p>
                <strong>Let op!</strong> Je bekijkt de website in preview modus. Je ziet niet altijd de content zoals de
                bezoekers van de site die zien.
              </p>
            </RichText>
            <ButtonLink href={targetUrl}>Preview sluiten</ButtonLink>
          </div>
        )}
        <ToggleButton
          label={showPreviewBar ? 'Previewbalk verbergen' : 'Previewbalk tonen'}
          externalOnChange={onHidePreviewBar}
        />
      </MaxWidth>
    </div>
  );
};
