import { groq } from 'next-sanity';

const NAME = 'imageAndNewsletterSignup';

export const imageAndNewsletterSignupQuery = groq`
  _type == '${NAME}' => {
    _type,
    title,
  },
`;

export type ImageAndNewsletterSignUpBlock = Sanity.Keyed<Sanity.Schema.ImageAndNewsletterSignup>;
