import { contentLinkQuery } from '../queries/helpers/link';
import { getClient } from '../sanity.server';
import { groq } from 'next-sanity';
import { SeoQuery, createSeoQuery } from '../queries/helpers/seoQuery';
import { SiteIdType } from '../sites';

export interface CorporateProjectsOverviewResponse
  extends Omit<Sanity.Schema.CorporateProjectsOverviewPage, 'seo' | 'siteId'> {
  _id: string;
  seo: SeoQuery;
  siteId?: SiteIdType;
}

export const createCorporateProjectsOverviewPageQuery = (
  siteId: SiteIdType
) => groq`*[_type == 'corporate-projectsOverviewPage' && siteId == $siteId] | order(_updatedAt desc)[0] {
  ${createSeoQuery(siteId)},
  slug,
  intro[] ${contentLinkQuery},
  order,
}`;

export const getCorporateProjectsOverviewPage = async (preview = false, siteId: SiteIdType = 'vandeklok') =>
  getClient(preview).fetch<CorporateProjectsOverviewResponse>(createCorporateProjectsOverviewPageQuery(siteId), {
    siteId,
  });
