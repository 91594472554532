import {
  AdditionalInfoFormFields,
  subscribeToNewsletterAdditionalData,
} from '@klokgroep/shared-components/utils/mailchimp';
import { Button } from '@klokgroep/shared-components/src/Button';
import { Input } from '@klokgroep/shared-components/src/Input';
import { SiteTheme } from '@klokgroep/sanity';
import { useCommonTranslations } from '@klokgroep/shared-components/utils/useTranslations';
import { UseFormRegister, UseFormSetValue, useForm } from 'react-hook-form';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';
import { useState, useId, useCallback, useEffect } from 'react';
import styles from './NewsletterAdditionalDataForm.module.css';
import { removeFalsyValues } from '@klokgroep/shared-components/utils/removeFalsyValues';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { captureException } from '@klokgroep/shared-components/utils/captureException';
import { Loader } from '@klokgroep/shared-components/src/Loader';

const NEWSLETTER_FORM_VALIDATION = object().shape({
  email: string().email('Vul een geldig e-mailadres in').required('Dit veld is verplicht'),
  gender: string().required('Dit veld is verplicht'),
  firstName: string().required('Dit veld is verplicht'),
  lastName: string().required('Dit veld is verplicht'),
});

interface Properties {
  onSuccess?: () => void;
  subscriberId?: string;
  type?: 'newsletter' | 'jobNews';
}

export const NewsletterAdditionalDataForm = ({ onSuccess, subscriberId, type = 'newsletter' }: Properties) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();

  const { theme } = useSiteInfo();
  const id = useId();
  const t = useCommonTranslations();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<AdditionalInfoFormFields>({
    defaultValues: { subscriberId },
    resolver: yupResolver(NEWSLETTER_FORM_VALIDATION),
  });

  useEffect(() => {
    setDefaultSubscriptionType(theme, setValue);
  }, [theme, setValue]);

  const onSubmit = useCallback(
    async (data: AdditionalInfoFormFields) => {
      setError(undefined);
      setIsLoading(true);
      try {
        const sanitized = removeFalsyValues(data);

        const response = await subscribeToNewsletterAdditionalData(sanitized, type);

        if (!response.ok) {
          throw new Error('Something went wrong');
        }

        onSuccess && onSuccess();
      } catch {
        setError('Er is een fout opgetreden.');
        captureException('Error while subscribing to newsletter', {
          tags: { fn: 'subscribeToNewsletterAdditionalData' },
        });
      } finally {
        setIsLoading(false);
      }
    },
    [onSuccess, type]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <input {...register('subscriberId')} type="hidden" />
      {subscriberId ? undefined : (
        <Input
          error={errors.email?.message}
          {...register('email')}
          label={t('newsletter_additional_data_form_email_label')}
          placeholder={t('newsletter_additional_data_form_email_placeholder')}
        />
      )}
      <fieldset className={styles.fieldset}>
        <legend className={styles.legend}>{t('newsletter_additional_data_form_salutation_label')}</legend>
        <div className={styles.inputContainer}>
          <input {...register('gender')} type="radio" id={`male-${id}`} value="male" />
          <label htmlFor={`male-${id}`}>{t('newsletter_additional_data_form_salutation_male')}</label>
        </div>
        <div className={styles.inputContainer}>
          <input {...register('gender')} type="radio" id={`female-${id}`} value="female" />
          <label htmlFor={`female-${id}`}>{t('newsletter_additional_data_form_salutation_female')}</label>
        </div>
        {!!errors.gender?.message && <p className={styles.fieldError}>{errors.gender?.message}</p>}
      </fieldset>
      <Input
        error={errors.firstName?.message}
        {...register('firstName')}
        label={t('newsletter_additional_data_form_first_name_label')}
        placeholder={t('newsletter_additional_data_form_first_name_placeholder')}
      />
      <Input
        {...register('middleName')}
        label={t('newsletter_additional_data_form_infix_label')}
        placeholder={t('newsletter_additional_data_form_infix_placeholder')}
      />
      <Input
        error={errors.lastName?.message}
        {...register('lastName')}
        label={t('newsletter_additional_data_form_last_name_label')}
        placeholder={t('newsletter_additional_data_form_last_name_placeholder')}
      />
      <Input
        {...register('companyName')}
        label={t('newsletter_additional_data_form_company_name_label')}
        placeholder={t('newsletter_additional_data_form_company_name_placeholder')}
      />
      {type === 'jobNews' ? undefined : <SiteFields id={id} register={register} />}
      <Button disabled={isLoading} type="submit">
        {t('newsletter_additional_data_form_submit_button_label')}
      </Button>
      {error ? <p className={styles.error}>{error}</p> : null}
      {isLoading ? <Loader /> : undefined}
    </form>
  );
};

const SiteFields = ({ register, id }: { register: UseFormRegister<AdditionalInfoFormFields>; id: string }) => {
  const { theme } = useSiteInfo();

  const t = useCommonTranslations();

  return (
    <fieldset className={styles.fieldset}>
      <legend className={styles.legend}>{t('newsletter_additional_data_form_sites_title')}</legend>
      {theme !== 'holding' && (
        <div className={styles.inputContainer}>
          <input {...register('subscribeToKlokgroep')} type="checkbox" id={`klokgroep-${id}`} />
          <label htmlFor={`klokgroep-${id}`}>Klokgroep</label>
        </div>
      )}
      {theme !== 'vandeklok' && (
        <div className={styles.inputContainer}>
          <input {...register('subscribeToVanDeKlok')} type="checkbox" id={`vandeklok-${id}`} />
          <label htmlFor={`vandeklok-${id}`}>Van de Klok</label>
        </div>
      )}
      {theme !== 'vanerk' && (
        <div className={styles.inputContainer}>
          <input {...register('subscribeToAdriaanVanErk')} type="checkbox" id={`vanerk-${id}`} />
          <label htmlFor={`vanerk-${id}`}>Adriaan van Erk</label>
        </div>
      )}
      {theme !== 'heilijgers' && (
        <div className={styles.inputContainer}>
          <input {...register('subscribeToHeilijgers')} type="checkbox" id={`heilijgers-${id}`} />
          <label htmlFor={`heilijgers-${id}`}>Heilijgers</label>
        </div>
      )}
      {theme !== 'novaform' && (
        <div className={styles.inputContainer}>
          <input {...register('subscribeToNovaform')} type="checkbox" id={`novaform-${id}`} />
          <label htmlFor={`novaform-${id}`}>Novaform</label>
        </div>
      )}
    </fieldset>
  );
};

function setDefaultSubscriptionType(theme: SiteTheme, setValue: UseFormSetValue<AdditionalInfoFormFields>) {
  theme === 'holding' && setValue('subscribeToKlokgroep', true);
  theme === 'vandeklok' && setValue('subscribeToVanDeKlok', true);
  theme === 'vanerk' && setValue('subscribeToAdriaanVanErk', true);
  theme === 'heilijgers' && setValue('subscribeToHeilijgers', true);
  theme === 'novaform' && setValue('subscribeToNovaform', true);
}
