import { SITES, Site, SiteIdType } from '@klokgroep/sanity';
import React, { createContext, useContext, useMemo, ReactNode } from 'react';

const DEFAULTS = {
  locale: 'nl' as const,
  siteId: 'holding' as const,
  theme: 'holding' as const,
  type: 'holding' as const,
};

type SiteInfoContextProperties = Pick<Site, 'locale' | 'siteId' | 'theme' | 'type'>;

const SiteInfoContext = createContext<SiteInfoContextProperties>(DEFAULTS);

interface Properties {
  children: ReactNode;
  siteId: SiteIdType;
}

const SiteInfoProvider = ({ children, siteId }: Properties) => {
  const value = useMemo(() => {
    const site = SITES.find((site) => site.siteId === siteId);

    if (!site) {
      return DEFAULTS;
    }

    const { locale, theme, type } = site;

    return { locale, siteId: site.siteId, theme, type };
  }, [siteId]);

  return <SiteInfoContext.Provider value={value}>{children}</SiteInfoContext.Provider>;
};

const useSiteInfo = () => useContext(SiteInfoContext);

export { SiteInfoProvider as SiteInfoProvider, useSiteInfo as useSiteInfo };
