declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

export function trackFormSubmit(formId: string) {
  trackOnDataLayer('gtm.formSubmit', { formId });
}

export function trackClick(clickId: string) {
  trackOnDataLayer('Click', { clickId });
}

function trackOnDataLayer(event: string, properties: Record<string, any>) {
  if (window.dataLayer) {
    window.dataLayer.push({ event, ...properties });
  }
}
