import { Fragment, useMemo } from 'react';
import styles from './SourceSet.module.css';

const DEFAULT_SIZES = [
  { width: 1400, minWidth: 1300 },
  { width: 1300, minWidth: 1200 },
  { width: 1200, minWidth: 1100 },
  { width: 1100, minWidth: 1000 },
  { width: 1000, minWidth: 900 },
  { width: 900, minWidth: 800 },
  { width: 800, minWidth: 768 },
  { width: 768, minWidth: 700 },
  { width: 700, minWidth: 600 },
  { width: 600, minWidth: 500 },
  { width: 500, minWidth: 400 },
  { width: 400, minWidth: 300 },
  { width: 300, minWidth: 1 },
];

interface Properties {
  alt?: string;
  crop?: 'entropy' | 'center';
  loading?: 'eager' | 'lazy';
  maxWidth?: number;
  sizes?: { width: number; minWidth: number; ratio?: number }[];
  src: string;
}

export const SourceSet = ({
  alt,
  crop = 'entropy',
  loading = 'lazy',
  maxWidth = DEFAULT_SIZES[0].width,
  sizes = DEFAULT_SIZES,
  src,
}: Properties) => {
  const sizesToUse = useMemo(() => sizes.filter(({ width }) => width <= maxWidth), [maxWidth, sizes]);

  return (
    <picture className={styles.container}>
      {sizesToUse.map(({ width, minWidth, ratio }) => (
        <Fragment key={`${width}-${minWidth}`}>
          <source
            srcSet={`${src}?w=${width}${
              ratio ? `&h=${Math.round(width / ratio)}` : ''
            }&dpr=2&auto=format&fit=crop&crop=${crop} ${width}w`}
            sizes={`(min-width: ${minWidth}px) ${width}px`}
            media={`(min-width: ${minWidth}px) and (-webkit-min-device-pixel-ratio: 1.25), (min-width: ${minWidth}px) and (min-resolution: 120dpi)`}
          />
          <source
            srcSet={`${src}?w=${width}${
              ratio ? `&h=${Math.round(width / ratio)}` : ''
            }&auto=format&fit=crop&crop=${crop} ${width}w`}
            sizes={`(min-width: ${minWidth}px) ${width}px`}
            media={`(min-width: ${minWidth}px)`}
          />
        </Fragment>
      ))}
      <img alt={alt} loading={loading} />
    </picture>
  );
};
