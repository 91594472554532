import { getClient } from '../sanity.server';
import { groq } from 'next-sanity';
import { SeoQuery, createSeoQuery } from './helpers/seoQuery';
import { SITES_WITHOUT_PROJECTS, SiteIdType } from '../sites';
import { contentLinkQuery } from './helpers/link';
import { blogContentModulesQueries, MappedBlogContentModules } from '../queries/blocks/blogContentModules';
import { LinkType } from './helpers';
import { contactPersonQuery } from '../queries/helpers/contactPersonQuery';
import { getRelatedContentItemsQueryStringForRoot } from '../queries/blocks/relatedContent';

export interface GetBlogPostsResponse extends Omit<Sanity.Schema.BlogPost, 'categories' | 'specialisms'> {
  _createdAt: string;
  categories?: Sanity.Schema.CategoryTaxonomy[];
  specialisms?: Sanity.Schema.SpecialismTaxonomy[];
  link: LinkType;
  title?: string;
}

export const blogItemFields = groq`
  "datePublished": select(
    defined( datePublished ) => datePublished,
    _updatedAt
  ),
  _createdAt,
  "title": blogHero.title,
  blogHero {
    ...,
    "images": images[]{
      _key,
      _type,
      asset,
      crop,
      hotspot,
      caption,
      "alt": coalesce(image.alt,image.asset->altText,string::split(image.asset->originalFilename,'.')[0]),
    },
  },
  siteId,
  _type,
  showOnHolding,
  categories[]->{slug,name},
  specialisms[]->{slug,name},
  slug,
  previewText,
  "link": {
    "type": "reference",
    "href": {
      slug,
     _type,
    },
  }
`;

export const createGetBlogPostsQuery = (
  siteIds?: string[],
  showOnHolding?: boolean,
  amount?: number,
  filterNoIndex?: boolean
) => groq`*[_type == 'blogPost'
    ${siteIds ? `&& siteId in $siteIds` : ``}
    ${showOnHolding ? `&& (showOnHolding == ${showOnHolding} || siteId == "holding")` : ``}
    ${filterNoIndex ? ` && seo.noIndex != true && parent->seo.noIndex != true` : ''}
  ] {
    ${blogItemFields}
  } | order(datePublished desc)${amount ? `[0...${amount}]` : ''}`;

interface GetBlogPostsOptions {
  amount?: number;
  filterNoIndex?: boolean;
  showOnHolding?: boolean;
  siteIds?: string[];
}

export const getBlogPosts = async (preview = false, options: GetBlogPostsOptions = {}) =>
  getClient(preview).fetch<GetBlogPostsResponse[]>(
    createGetBlogPostsQuery(options?.siteIds, options?.showOnHolding, options?.amount, options?.filterNoIndex),
    options
  );

export const getBlogPostsSlugs = async (
  preview = false,
  siteIds: SiteIdType[] = SITES_WITHOUT_PROJECTS.map(({ siteId }) => siteId),
  filterNoIndex?: boolean,
  showOnHolding?: boolean
) =>
  getClient(preview).fetch<{ slug: string; siteId?: SiteIdType }[]>(
    groq`*[_type == 'blogPost' && defined(slug.current) && siteId in $siteIds
    ${filterNoIndex ? ` && seo.noIndex != true && parent->seo.noIndex != true` : ''}
    ${showOnHolding ? `&& (showOnHolding == ${showOnHolding} || siteId == "holding")` : ``}
  ] | order(_updatedAt desc, _createdAt desc) {
    "slug": slug.current,
    siteId,
  }`,
    { siteIds }
  );

export interface GetBlogPostForSlugResponse
  extends Omit<Sanity.Schema.BlogPost, 'seo' | 'categories' | 'specialisms' | 'relatedContent' | 'blogContentModules'> {
  _createdAt: string;
  seo?: SeoQuery;
  categories?: Sanity.Schema.CategoryTaxonomy[];
  blogContentModules: MappedBlogContentModules;
  specialisms?: Sanity.Schema.SpecialismTaxonomy[];
  relatedContent?: { items: GetBlogPostsResponse[]; documentId?: string };
  shareFooterColor?: 'white' | 'lightGray';
}

export const getBlogPostQuery = (siteId?: SiteIdType, preview?: boolean) => groq`*[${
  siteId ? `(siteId == '${siteId}' || showOnHolding) &&` : ``
} _type == 'blogPost' && lower(slug.current) == lower($slug)][0] {
  ${createSeoQuery()},
  ${contactPersonQuery},
  _id,
  _type,
  _createdAt,
  slug,
  siteId,
  showOnHolding,
  categories[]->{slug,name},
  specialisms[]->{slug,name},
  shareFooterColor,
  blogHero {
    ...,
    "images": images[]{
      _key,
      _type,
      asset,
      crop,
      hotspot,
      caption,
      "alt": coalesce(image.alt,image.asset->altText,string::split(image.asset->originalFilename,'.')[0]),
    },
  },
  previewText,
  "datePublished": select(
    defined( datePublished ) => datePublished,
    _updatedAt
  ),
  blogContentModules[] { ...select( ${blogContentModulesQueries} )}, 
  content[] ${contentLinkQuery},
  relatedContent {
    ${getRelatedContentItemsQueryStringForRoot(siteId, preview)}
  }
}`;

export const getBlogPostForSlug = async (slug: string, preview = false, siteId?: SiteIdType) =>
  getClient(preview).fetch<GetBlogPostForSlugResponse>(getBlogPostQuery(siteId, preview), { slug });

const createRelatedBlogPostsQuery = (showOnHolding?: boolean, siteId?: SiteIdType) => groq`*[${
  siteId ? `siteId == $siteId &&` : ``
} _type == 'blogPost' && !(slug.current in $slugsToExclude) && 
(category->slug.current == $category || categories[0]->slug.current == $category)
${showOnHolding ? `&& (showOnHolding == true || siteId == "holding")` : ``} 
] {
  ${blogItemFields},
  showOnHolding,
}[0...3] | order(datePublished desc)`;

export const getRelatedBlogPosts = async (
  slugsToExclude: string[],
  category: string | null = null,
  preview = false,
  showOnHolding?: boolean,
  siteId?: SiteIdType
) =>
  getClient(preview).fetch<GetBlogPostsResponse[]>(createRelatedBlogPostsQuery(showOnHolding, siteId), {
    category,
    slugsToExclude,
    siteId: siteId || null,
  });
