import { Modal } from '@klokgroep/shared-components/src/Modal';
import { NewsletterAdditionalDataForm } from '@klokgroep/shared-components/src/Newsletter';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import styles from './NewsletterAdditionalDataPopup.module.css';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';
import { useCommonTranslations } from '@klokgroep/shared-components/utils/useTranslations';

interface Properties {
  onClose: () => void;
  subscriberId?: string;
  type?: 'newsletter' | 'jobNews';
}

export const NewsletterAdditionalDataPopup = ({ onClose, subscriberId, type }: Properties) => {
  const { theme } = useSiteInfo();
  const t = useCommonTranslations();

  const newsLetterType = theme === 'heilijgers' ? 'E-Zine' : 'Kloksignaal';

  return (
    <Modal onClose={onClose}>
      <div className={styles.container}>
        <RichText>
          <p>{t('newsletter_additional_data_popup', { newsLetterType })}</p>
        </RichText>
        <NewsletterAdditionalDataForm onSuccess={onClose} subscriberId={subscriberId} type={type} />
      </div>
    </Modal>
  );
};
