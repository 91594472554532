import { getSanityConfig } from './sanityConfig';
import { definePreview } from 'next-sanity/preview';
import { createClient } from '@sanity/client';

export { PreviewSuspense } from 'next-sanity/preview';

export const usePreview = (token: string, query: string, parameters: any) => {
  const sanityConfig = getSanityConfig();
  return definePreview({
    // TODO: figure out a way to improve this without the high limit, see https://sanity-io-land.slack.com/archives/C9Z7RC3V1/p1680073877988979
    documentLimit: 20_000,
    projectId: sanityConfig.projectId || '',
    dataset: sanityConfig.dataset || '',
  })(token || null, query, parameters);
};

export const getClient = () => {
  const sanityConfig = getSanityConfig();
  return createClient({
    apiVersion: sanityConfig.apiVersion,
    dataset: sanityConfig.dataset,
    projectId: sanityConfig.projectId,
    useCdn: true,
  });
};
