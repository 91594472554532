import { ContactFormInputsWithCloudinaryUrls, SiteIdType } from '@klokgroep/sanity';

export const MEMBER_EXISTS_TITLE = 'Member Exists';

export interface AdditionalInfoFormFields {
  subscriberId: string;
  email: string;
  gender: 'male' | 'female' | null;
  firstName: string;
  lastName: string;
  middleName: string;
  companyName: string;
  subscribeToKlokgroep?: boolean;
  subscribeToVanDeKlok: boolean;
  subscribeToAdriaanVanErk: boolean;
  subscribeToHeilijgers: boolean;
  subscribeToNovaform: boolean;
}

export const sendContact = (data: ContactFormInputsWithCloudinaryUrls) =>
  fetch('/api/forms/contact', {
    method: 'POST',
    body: JSON.stringify(data),
  });

export const subscribeToNewsletter = (
  email: string,
  siteId: SiteIdType,
  type: 'newsletter' | 'jobNews' = 'newsletter'
) =>
  fetch(`/api/forms/kloksignaal`, {
    method: 'POST',
    body: JSON.stringify({ email, interest: siteId, type }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const subscribeToNewsletterAdditionalData = (
  body: Partial<AdditionalInfoFormFields>,
  type: 'newsletter' | 'jobNews' = 'newsletter'
) =>
  fetch(`/api/forms/kloksignaal-additional-info`, {
    method: 'POST',
    body: JSON.stringify({ ...body, type }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
