import { groq } from 'next-sanity';
import { LinkType } from '../helpers';
import { linkQuery } from '../helpers/link';

const NAME = 'imagesWithLinks';

export const imagesWithLinksQuery = groq`
  _key,
  _type,
  backgroundColor,
  title,
  items[] {
    ...,
    "image": link.href->pageHero.image,
    video,
    ${linkQuery},
  }
`;

export const imagesWithLinksModuleQuery = groq`
  _type == '${NAME}' => {
    ${imagesWithLinksQuery}
  },
`;

export interface ImagesWithLinksBlock extends Omit<Sanity.Keyed<Sanity.Schema.ImagesWithLinks>, 'items'> {
  items?: Sanity.Schema.ImagesWithLinks['items'] &
    {
      image?: Sanity.Schema.PageHero['image'];
      link: LinkType;
    }[];
}
