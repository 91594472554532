import { groq } from 'next-sanity';

const NAME = 'embed';

export const embedQuery = groq`
  _type == '${NAME}' => {
    _type,
    _key,
    aspectRatio,
    url
  },
`;

export interface EmbedBlockProperties extends Sanity.Schema.Embed {
  _key: string;
}
