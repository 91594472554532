import { Button } from '@klokgroep/shared-components/src/Button';
import { captureException } from '@klokgroep/shared-components/utils/captureException';
import { ChevronButtonInPentagon } from '@klokgroep/shared-components/src/ChevronButtonInPentagon';
import { FormEventHandler, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { MEMBER_EXISTS_TITLE, subscribeToNewsletter } from '@klokgroep/shared-components/utils/mailchimp';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import { useCommonTranslations } from '@klokgroep/shared-components/utils/useTranslations';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';
import styles from './NewsletterForm.module.css';
import { useRouter } from 'next/router';
import { trackFormSubmit } from '@klokgroep/shared-components/utils/tracking';

interface Properties {
  gtmFormId?: string;
  setSubscriberId?: (value: SetStateAction<undefined>) => void;
  type?: 'newsletter' | 'jobNews';
}

export const NewsletterForm = ({ gtmFormId, setSubscriberId, type = 'newsletter' }: Properties) => {
  const router = useRouter();
  const { theme, siteId } = useSiteInfo();
  const t = useCommonTranslations();

  const [email, setEmail] = useState('');
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setEmail('');
    setError(undefined);
    setSuccess(false);
  }, [router.asPath]);

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    async (event) => {
      try {
        event.preventDefault();

        setError(undefined);
        const response = await subscribeToNewsletter(email, siteId, type);
        const json = await response.json();

        if (!response.ok) {
          if (json.error === MEMBER_EXISTS_TITLE) {
            return setError(json.message);
          } else {
            throw new Error('Something went wrong');
          }
        }

        setSubscriberId ? setSubscriberId(json.subscriberId) : undefined;

        if (gtmFormId) {
          trackFormSubmit(gtmFormId);
        }

        setSuccess(true);
      } catch (error) {
        captureException(error);
        setError(t('newsletter_tile_general_error'));
      }
    },
    [email, gtmFormId, setSubscriberId, siteId, t, type]
  );

  const buttonTheme = useMemo(
    () => (theme === 'novaform' ? 'redText' : theme === 'vandeklok' ? 'orange' : undefined),
    [theme]
  );

  if (success) {
    return (
      <RichText>
        <p>{t('newsletter_tile_subscribed_successfully')}</p>
      </RichText>
    );
  }

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={onSubmit}>
        <input
          className={styles.input}
          name="email"
          onChange={(event) => setEmail(event.target.value)}
          placeholder={t('newsletter_tile_form_email_placeholder')}
          required
          type="email"
          value={email}
        />
        {theme === 'holding' || !theme ? (
          <ChevronButtonInPentagon small type="submit" />
        ) : (
          <Button type="submit" theme={buttonTheme}>
            {t('newsletter_tile_form_submit_button_label')}
          </Button>
        )}
      </form>

      {error ? <div className={styles.error}>{error}</div> : undefined}
    </div>
  );
};
