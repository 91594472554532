import { groq } from 'next-sanity';
import { SiteIdType } from '../sites';
import { linkQuery, LinkQuery, contentLinkQuery } from './helpers/link';
import { getSitewideDataFilter } from '../utils';

export interface PopupQuery {
  show?: boolean;
  content?: Array<Sanity.Keyed<Sanity.Block>>;
  hideInterval?: number;
  link?: LinkQuery;
}

export const getPopupQuery = (siteId?: SiteIdType) => {
  const siteIdFilter =
    siteId === 'holding' || siteId === undefined
      ? `_type == 'holding-settings'`
      : `_type == 'corporate-settings' && siteId == '${siteId}'`;
  const filter = getSitewideDataFilter(siteIdFilter);
  return groq`*${filter}[0].popup {
  content[] ${contentLinkQuery},
  show,
  hideInterval,
  "link": ${linkQuery},
}`;
};
