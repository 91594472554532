import { getSiteLogo } from '@klokgroep/shared-components/src/Logos';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import { XMarkIcon } from '@heroicons/react/24/solid';
import cx from 'classnames';
import React, { ReactNode, useEffect, useMemo, useRef } from 'react';
import styles from './Modal.module.css';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';
import { TitleOverTwoRules } from '@klokgroep/shared-components/src/TitleOverTwoRules';

interface ModalProperties {
  children: ReactNode;
  onClose?: () => void;
  showLogo?: boolean;
  smallPadding?: boolean;
  title?: string;
  titleOverTwoRules?: boolean;
  modalSize?: 'medium' | 'large';
  closeButtonFloating?: boolean;
}

const FOCUS_ELEMENTS_QUERY =
  'a[href], button:not([disabled]), details, input:not([readonly]), select, textarea, [tabindex]:not([tabindex^="-"])';

const trapFocusInsideModal = (event: KeyboardEvent, element: HTMLElement | undefined | null) => {
  if (event.key !== 'Tab') return;

  const focusableModalElements = element?.querySelectorAll<HTMLElement>(FOCUS_ELEMENTS_QUERY);

  if (focusableModalElements && focusableModalElements?.length > 0) {
    const firstElement = focusableModalElements[0];
    // eslint-disable-next-line unicorn/prefer-at
    const lastElement = focusableModalElements[focusableModalElements.length - 1];

    // if going forward by pressing tab and lastElement is active shift focus to first focusable element
    if (!event.shiftKey && document.activeElement === lastElement) {
      firstElement.focus();
      event.preventDefault();
    }

    if (event.shiftKey && document.activeElement === firstElement) {
      lastElement.focus();
      event.preventDefault();
    }
  }
};

export const Modal = ({
  onClose,
  children,
  showLogo = true,
  title,
  smallPadding,
  titleOverTwoRules,
  modalSize,
  closeButtonFloating,
}: ModalProperties) => {
  const contentReference = useRef<HTMLElement>(null);

  const { siteId } = useSiteInfo();

  useEffect(() => {
    const body = document.querySelector('body');

    if (body) {
      body.style.overflow = 'hidden';
    }

    return () => {
      if (body) {
        body.style.overflow = '';
      }
    };
  }, []);

  useEffect(() => {
    function onKeyDown(event: KeyboardEvent) {
      if (onClose && event.key === 'Escape') {
        onClose();
      }

      if (event.key === 'Tab') {
        trapFocusInsideModal(event, contentReference?.current);
      }
    }

    document.addEventListener('keydown', onKeyDown, false);

    return () => document.removeEventListener('keydown', onKeyDown, false);
  }, [onClose]);

  useEffect(() => {
    const firstElementInContent = contentReference.current?.querySelector<HTMLElement>(FOCUS_ELEMENTS_QUERY);

    if (firstElementInContent) {
      firstElementInContent.focus();
    }
  }, []);

  const Logo = useMemo(() => (showLogo ? getSiteLogo(siteId) : null), [showLogo, siteId]);

  return (
    <div className={cx(styles.container, siteId)}>
      <div className={styles.overlay} onClick={onClose} aria-hidden="true" />
      <section
        aria-modal="true"
        className={cx(styles.content, { [styles.smallPadding]: smallPadding, [styles.large]: modalSize === 'large' })}
        role="dialog"
        ref={contentReference}>
        <header className={cx(styles.header, { [styles.noMargin]: closeButtonFloating })}>
          {Logo ? <Logo /> : title ? <ModalTitle title={title} titleOverTwoRules={titleOverTwoRules} /> : <div />}
          {onClose ? (
            <button
              className={cx(styles.closeButton, { [styles.floating]: closeButtonFloating })}
              onClick={onClose}
              type="button">
              <XMarkIcon />
            </button>
          ) : undefined}
        </header>
        {children}
      </section>
    </div>
  );
};

const ModalTitle = ({ title, titleOverTwoRules }: { title: string; titleOverTwoRules?: boolean }) =>
  titleOverTwoRules ? (
    <RichText>
      <TitleOverTwoRules as="h2">{title}</TitleOverTwoRules>
    </RichText>
  ) : (
    <RichText>
      <h3>{title}</h3>
    </RichText>
  );
