import { RiBuilding2Line, RiBuilding3Line, RiBuilding4Line, RiHome4Line, RiHomeSmile2Line } from 'react-icons/ri';
import { TbCrane } from 'react-icons/tb';

/*
  Note: The isNotInProduction is a temporary key to determine if a site is in production or not.
  We use this to filter out some things on the holding site that are not yet ready for production because the corporate sites are not yet ready.
  TODO: The isNotInProduction key should be removed for the connected site when a corporate site is deployed to production.
*/

export const SITES = [
  // Holding
  {
    icon: RiBuilding2Line,
    siteId: 'holding' as const,
    theme: 'holding' as const,
    title: 'KlokGroep',
    type: 'holding' as const,
    developmentHostname: 'http://localhost:3000',
    previewHostname: 'https://klokgroep-development.vercel.app',
    acceptanceHostname: 'https://klokgroep-holding-acceptatie.vercel.app',
    productionHostname: 'https://klokgroep.nl',
    companyName: 'KlokGroep',
  },

  // Corporates
  {
    icon: RiBuilding3Line,
    siteId: 'vandeklok' as const,
    theme: 'vandeklok' as const,
    title: 'Van de Klok',
    type: 'corporate' as const,
    developmentHostname: 'http://vandeklok.localhost:3001',
    previewHostname: 'https://vandeklok-development.vercel.app',
    acceptanceHostname: 'https://vandeklok-acceptance.vercel.app',
    productionHostname: 'https://vandeklok.nl',
    companyName: 'Van de Klok',
    isNotInProduction: false,
  },
  {
    icon: RiBuilding4Line,
    siteId: 'novaform' as const,
    theme: 'novaform' as const,
    title: 'Novaform',
    type: 'corporate' as const,
    developmentHostname: 'http://novaform.localhost:3001',
    previewHostname: 'https://novaform-development.vercel.app',
    acceptanceHostname: 'https://novaform-acceptance.vercel.app',
    productionHostname: 'https://novaform.nl',
    companyName: 'Novaform Nederland',
    isNotInProduction: false,
  },
  {
    icon: RiBuilding4Line,
    siteId: 'novaform-de' as const,
    theme: 'novaform' as const,
    title: 'Novaform DE',
    type: 'corporate' as const,
    developmentHostname: 'http://novaform-de.localhost:3001',
    previewHostname: 'https://novaform-de-development.vercel.app',
    acceptanceHostname: 'https://novaform-de-acceptance.vercel.app',
    productionHostname: 'https://novaform.de',
    locale: 'de',
    companyName: 'Novaform Deutschland',
    isNotInProduction: false,
  },
  {
    icon: RiBuilding4Line,
    siteId: 'novaform-pl' as const,
    theme: 'novaform' as const,
    title: 'Novaform PL',
    type: 'corporate' as const,
    developmentHostname: 'http://novaform-pl.localhost:3001',
    previewHostname: 'https://novaform-pl-development.vercel.app',
    acceptanceHostname: 'https://novaform-pl-acceptance.vercel.app',
    productionHostname: 'https://novaformpolska.pl',
    locale: 'pl',
    companyName: 'Novaform Polska',
    isNotInProduction: false,
  },
  {
    icon: RiHome4Line,
    siteId: 'heilijgers' as const,
    theme: 'heilijgers' as const,
    title: 'Heilijgers',
    type: 'corporate' as const,
    developmentHostname: 'http://heilijgers.localhost:3001',
    previewHostname: 'https://heilijgers-development.vercel.app',
    acceptanceHostname: 'https://heilijgers-acceptance.vercel.app',
    productionHostname: 'https://heilijgers.nl',
    companyName: 'Heilijgers',
    isNotInProduction: false,
  },
  {
    icon: RiHomeSmile2Line,
    siteId: 'vanerk' as const,
    theme: 'vanerk' as const,
    title: 'Adriaan van Erk',
    type: 'corporate' as const,
    developmentHostname: 'http://vanerk.localhost:3001',
    previewHostname: 'https://vanerk-development.vercel.app',
    acceptanceHostname: 'https://vanerk-acceptance.vercel.app',
    productionHostname: 'https://adriaanvanerk.nl',
    companyName: 'Adriaan van Erk',
    isNotInProduction: false,
  },
  {
    icon: RiHomeSmile2Line,
    siteId: 'vanerk-timmerfabriek' as const,
    theme: 'vanerk' as const,
    title: 'Timmerfabriek Adriaan van Erk',
    type: 'corporate' as const,
    developmentHostname: 'http://timmerfabriek.localhost:3001',
    previewHostname: 'https://timmerfabriek-development.vercel.app',
    acceptanceHostname: 'https://timmerfabriek-acceptance.vercel.app',
    productionHostname: 'https://timmerfabriek.vercel.app',
    companyName: 'Adriaan van Erk Groep',
    isNotInProduction: true,
  },

  // Projects
  {
    icon: TbCrane,
    siteId: 'loyd' as const,
    theme: 'project' as const,
    title: 'Loyd',
    type: 'project' as const,
    developmentHostname: 'http://loyd.localhost:3002',
    productionHostname: 'https://loyd.vercel.app',
  },
  {
    icon: TbCrane,
    siteId: 'hooiwal' as const,
    theme: 'project' as const,
    title: 'Hooiwal',
    type: 'project' as const,
    developmentHostname: 'http://hooiwal.localhost:3002',
    productionHostname: 'https://hooiwal.vercel.app',
  },
];

export const CORPORATE_SITES = SITES.filter((site) => site.type === 'corporate');
export const SITES_WITHOUT_PROJECTS = SITES.filter((site) => site.type !== 'project');

export type Site = (typeof SITES)[number];
export type SiteTheme = Site['theme'];
export type SiteIdType = Site['siteId'];

export type SiteIdTypeWithoutProjects =
  | 'holding'
  | 'vandeklok'
  | 'novaform'
  | 'novaform-de'
  | 'novaform-pl'
  | 'heilijgers'
  | 'vanerk'
  | undefined;

export type SiteIdTypeForCorporate =
  | 'vandeklok'
  | 'novaform'
  | 'novaform-de'
  | 'novaform-pl'
  | 'heilijgers'
  | 'vanerk'
  | undefined;

export const getSiteThemeFromSiteId = (siteId: SiteIdType): SiteTheme =>
  SITES.find((site) => site.siteId === siteId)?.theme ?? 'holding';

export function getCurrentEnvironmentHostNameForSiteId(siteId: SiteIdType) {
  return process.env.NEXT_PUBLIC_IS_ACCEPTANCE
    ? SITES.find((site) => siteId === site.siteId)?.acceptanceHostname
    : process.env.NODE_ENV === 'production'
    ? SITES.find((site) => siteId === site.siteId)?.productionHostname
    : SITES.find((site) => siteId === site.siteId)?.developmentHostname;
}
