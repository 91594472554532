import { groq } from 'next-sanity';
import { SiteIdType } from '../sites';
import { LinkType } from '../queries/helpers/interfaces';
import { linkQueryBody } from './helpers/link';
import { getSitewideDataFilter } from '../utils';

export interface SubNavigationItem {
  _type: 'subMenu';
  _key: string;
  label: string;
  items: (SubNavigationItem | NavigationItem)[];
}

export type NavigationItem = LinkType;
interface NavigationMenu {
  title?: string;
  items: NavigationItem[];
}

export interface MainNavigationMenu {
  items?: (SubNavigationItem | NavigationItem)[];
}

export interface NavigationQuery {
  mainNavigation: MainNavigationMenu;
  firstFooterMenu: NavigationMenu;
  secondFooterMenu: NavigationMenu;
  subFooterMenu: NavigationMenu;
  jobNavigationItems: NavigationMenu;
}

export const getNavigationQuery = (siteId?: SiteIdType) => {
  const navigationTemplateString = groq`..., items[]{
    ${linkQueryBody}
  }`;

  const siteIdFilter =
    siteId === 'holding' || siteId === undefined
      ? `_type == 'holding-navigation'`
      : `_type == 'corporate-navigation' && siteId == '${siteId}'`;

  const filter = getSitewideDataFilter(siteIdFilter);

  return groq`*${filter}[0] {
      mainNavigation {
        items[]{
          ${linkQueryBody}
          items[]{
            ${linkQueryBody}
            items[]{
              ${linkQueryBody}
            }
          }
        }
      },
      firstFooterMenu { ${navigationTemplateString} },
      secondFooterMenu { ${navigationTemplateString} },
      subFooterMenu { ${navigationTemplateString} },
      jobNavigationItems {
        items[]{
          ${linkQueryBody}
          items[]{
            ${linkQueryBody}
            items[]{
              ${linkQueryBody}
            }
          }
        }
      },
    }`;
};
