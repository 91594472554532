import { groq } from 'next-sanity';
import { contentLinkQuery } from '../helpers/link';
import { BlockContainerWithBackgroundProperties } from '@klokgroep/shared-components/src/BlockContainerWithBackground';

const NAME = 'content';

export const contentQuery = groq`
  _type == '${NAME}' => {
    _type,
    _key,
    content[] ${contentLinkQuery},
    backgroundColor,
  },
`;

export type ContentBlock = {
  _type: 'content';
  _key: string;
  content?: Array<Sanity.Keyed<Sanity.Block>>;
  backgroundColor?: BlockContainerWithBackgroundProperties['backgroundColor'];
  noMarginOverflow?: boolean;
};
