import { getClient } from '../sanity.server';
import { groq } from 'next-sanity';
import { MappedCorporateContentModules, getCorporateContentModulesQueries } from '../corporateQueries/blocks';
import { SeoQuery, createSeoQuery } from '../queries/helpers/seoQuery';
import { CORPORATE_SITES, SiteIdType } from '../sites';
import {
  RelatedContentBlockProperties,
  getRelatedContentItemsQueryStringForRoot,
} from '../queries/blocks/relatedContent';
import { mediaItemsQuery } from '../queries/helpers/mediaItems';
import { contactPersonQuery } from '@klokgroep/sanity/src/queries/helpers/contactPersonQuery';
import { contentLinkQuery } from '@klokgroep/sanity/src/queries/helpers/link';

interface MappedProjectProperties {
  locationType: { label?: string; slug?: { current?: string } }[] | undefined;
  status: { label?: string; slug?: { current?: string } }[] | undefined;
  activities: { label?: string; slug?: { current?: string } }[] | undefined;
  workAreas: { label?: string; slug?: { current?: string } }[] | undefined;
  concepts: { label?: string; slug?: { current?: string } }[] | undefined;
  features: { label?: string; slug?: { current?: string } }[] | undefined;
}

type ProjectProperties = Omit<
  NonNullable<Sanity.Schema.CorporateProject['properties']>,
  'locationType' | 'status' | 'activities' | 'workAreas' | 'concepts' | 'features'
> &
  MappedProjectProperties;

export interface GetCorporateProjectsResponse
  extends Omit<Sanity.Schema.CorporateProject, 'properties' | 'relatedContent'> {
  _createdAt: string;
  image?: NonNullable<NonNullable<Sanity.Schema.CorporateProject['corporateProjectHero']>['overviewImage']>;
  properties: MappedProjectProperties | undefined;
  relatedContent: RelatedContentBlockProperties;
  childProjectsAmount?: number;
}

type GetCorporateProjectSlugsResponse = { slug: string; siteId?: SiteIdType }[];

export const getCorporateProjectSlugsWithSiteIds = async (
  preview = false,
  filterNoIndex?: boolean,
  showOnHolding?: boolean
) =>
  getClient(preview)
    .fetch<GetCorporateProjectSlugsResponse>(groq`*[_type == 'corporate-project' && defined(slug.current)${
    showOnHolding ? `&& (showOnHolding == ${showOnHolding} || siteId == "holding")` : ``
  }${
    filterNoIndex ? ` && seo.noIndex != true && parent->seo.noIndex != true` : ''
  }] | order(_updatedAt desc, _createdAt desc) {
      "slug": slug.current,
       siteId,
    }`);

export const getCorporateProjectFields = () => groq`
  "datePublished": select(
    defined( datePublished ) => datePublished,
    _updatedAt
  ),
  
  _id,
  _createdAt,
  title,
  location,
  slug,
  geo,
  siteId,

  properties {
    locationType[]->{label,slug},
    status[]->{label,slug},
    activities[]->{label,slug},
    workAreas[]->{label,slug},
    concepts[]->{label,slug},
    features[]->{label,slug},
  },

  "image": coalesce(corporateProjectHero.overviewImage, corporateProjectHero.mediaItems[0]),

  "childProjectsAmount": count(*[ _type == 'corporate-project' && parent._ref == ^._id]),
`;

export const createGetCorporateProjectsQuery = ({
  siteIds,
  showOnHolding,
  amount,
  parentSlug,
}: {
  siteIds?: string[];
  showOnHolding?: boolean;
  amount?: number;
  parentSlug?: string;
} = {}) => groq`*[_type == 'corporate-project' ${siteIds ? `&& siteId in $siteIds` : ``}${
  showOnHolding ? `&& (showOnHolding == ${showOnHolding} || siteId == "holding")` : ``
}${parentSlug ? `&& parent->slug.current =='${parentSlug}'` : ``}] {
    ${getCorporateProjectFields()}
  } | order(datePublished desc)${amount ? `[0...${amount}]` : ''}`;

interface GetCorporateProjectOptions {
  amount?: number;
  siteIds?: string[];
  showOnHolding?: boolean;
  parentSlug?: string;
}

export const getCorporateProjects = async (preview = false, options: GetCorporateProjectOptions = {}) =>
  getClient(preview).fetch<GetCorporateProjectsResponse[]>(createGetCorporateProjectsQuery({ ...options }), options);

export interface GetCorporateProjectForSlugResponse
  extends Omit<
    Sanity.Schema.CorporateProject,
    'seo' | 'corporateContentModules' | 'properties' | 'relatedContent' | 'parent'
  > {
  corporateContentModules: MappedCorporateContentModules;
  properties: ProjectProperties | undefined;
  seo: SeoQuery;
  relatedContent: RelatedContentBlockProperties;
  parent?: {
    _type?: Sanity.Schema.CorporateProject['_type'];
    title?: string;
    slug?: {
      _type: 'slug';
      current?: string;
    };
  };
}

export interface GetCorporateProjectForSlugResponseWithChildProjects
  extends Omit<GetCorporateProjectForSlugResponse, 'childProjects'> {
  childProjects?: GetCorporateProjectsResponse[];
}
export const createGetCorporateProjectQueryForSlug = (
  siteId: SiteIdType,
  filter?: string,
  preview?: boolean
) => groq`*[${filter}] | order(_updatedAt desc)[0]  {
    _id,
    _type,
    siteId,

    parent->{
      _type,
      slug,
      title,
    },

    "datePublished": select(
      defined( datePublished ) => datePublished,
      _updatedAt
    ),

    _createdAt,
    ${createSeoQuery(siteId)},
    ${contactPersonQuery},

    title,
    location,
    slug,
    url,

    corporateProjectHero {
      intro[] ${contentLinkQuery},
      ${mediaItemsQuery},
    },

    properties {
      locationType[]->{label,slug},
      status[]->{label,slug},
      activities[]->{label,slug},
      workAreas[]->{label,slug},
      concepts[]->{label,slug},
      features[]->{label,slug},

      buildingsAmount,
      architect,
      director,
      realization,
      constructorName,
      installationConsultant,
      delivery,
      hideDelivery,
    },

    relatedContent {
    _type,
    _key,
   title,
   backgroundColor,
   ${getRelatedContentItemsQueryStringForRoot(siteId, preview)},
  },

    corporateContentModules[] { ...select( ${getCorporateContentModulesQueries(siteId, preview)} ), },
    
  }`;

export const getCorporateProjectForSlugAndSiteId = async (slug: string, siteId: SiteIdType, preview = false) => {
  const filter = groq`(_type == 'corporate-project') ${
    siteId ? `&& (siteId == "holding" || siteId == $siteId)` : ''
  } && lower(slug.current) == lower($slug)`;

  const [childProjects, properties] = await Promise.all([
    getClient(preview).fetch<GetCorporateProjectsResponse[]>(
      createGetCorporateProjectsQuery({
        siteIds: [siteId],
        showOnHolding: undefined,
        amount: undefined,
        parentSlug: slug,
      }),
      { slug, siteIds: [siteId] }
    ),
    getClient(preview).fetch<GetCorporateProjectForSlugResponse>(
      createGetCorporateProjectQueryForSlug(siteId, filter, preview),
      {
        slug,
        siteId,
      }
    ),
  ]);

  const childPropertiesWithMaybeItems = childProjects.length === 0 ? undefined : childProjects;

  return {
    ...properties,
    childProjects: childPropertiesWithMaybeItems,
  };
};

export const getCorporateProjectForSlug = async (slug: string, preview = false, siteId: SiteIdType) => {
  const siteIds = CORPORATE_SITES.map(({ siteId }) => siteId);

  const filter = groq`(_type == 'corporate-project') && lower(slug.current) == lower($slug)`;

  const [childProjects, properties] = await Promise.all([
    getClient(preview).fetch<GetCorporateProjectsResponse[]>(
      createGetCorporateProjectsQuery({ siteIds, showOnHolding: undefined, amount: undefined, parentSlug: slug }),
      { slug, siteIds }
    ),

    getClient(preview).fetch<GetCorporateProjectForSlugResponse>(
      createGetCorporateProjectQueryForSlug(siteId, filter, preview),
      { slug }
    ),
  ]);

  return {
    ...properties,
    childProjects,
  };
};
