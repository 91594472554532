import { InputContainer, InputContainerProperties } from '@klokgroep/shared-components/src/InputContainer';
import React, { forwardRef, InputHTMLAttributes, useMemo } from 'react';
import styles from './Input.module.css';
import cx from 'classnames';

export interface InputProperties
  extends Omit<InputContainerProperties, 'children'>,
    InputHTMLAttributes<HTMLInputElement> {
  type?: 'date' | 'email' | 'number' | 'password' | 'tel' | 'text' | 'file' | 'hidden';
}

export const Input = forwardRef<HTMLInputElement, InputProperties>(
  ({ description, error, label, type = 'text', ...rest }, reference) => {
    const classNames = useMemo(() => cx(styles.input, { [styles.error]: !!error }), [error]);

    return (
      <InputContainer description={description} error={error} label={label}>
        <input className={classNames} type={type} ref={reference} {...rest} />
      </InputContainer>
    );
  }
);

Input.displayName = 'Input';
