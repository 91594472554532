import { groq } from 'next-sanity';

export const itemWithIconsQuery = groq`
  _type == 'itemsWithIcons' => {
    _type,
    _key,
    items,
  },
`;

export type ItemsWithIconsBlock = Sanity.Keyed<Sanity.Schema.ItemsWithIcons>;
