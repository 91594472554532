import { groq } from 'next-sanity';
import { imageQuery } from '../helpers/image';
import { contentLinkQuery, linkQuery } from '../helpers/link';

const NAME = 'testimonial';

export const testimonialQuery = groq`
  _type == '${NAME}' => {
    _type,
    _key,
    backgroundColor,
    title,
    quote[] ${contentLinkQuery},
    name,
    role,
    ${linkQuery},
    ${imageQuery},
  },
`;

export interface TestimonialBlock extends Omit<Sanity.Keyed<Sanity.Schema.Testimonial>, 'link'> {
  link?: {
    label?: string;
    href?: { _type: Sanity.Schema.Document['_type']; slug?: { _type: 'slug'; current?: string } };
  };
}
