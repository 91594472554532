import { groq } from 'next-sanity';
import { getNavigationQuery, NavigationQuery } from './navigation';
import { getSocialsQuery, SocialsQuery } from './socials';
import { getClient } from '../sanity.server';
import { getPopupQuery, PopupQuery } from './popup';
import { SiteIdType } from '../sites';
import { createSchemaQuery } from '../queries/helpers/schemaQuery';

interface SitewideData {
  mapStyleString?: string;
  leadInfoKey?: string;
  navigation?: NavigationQuery;
  popup?: PopupQuery;
  socials?: SocialsQuery;
}

export const getSitewideData = async (preview = false, siteId: SiteIdType = 'holding') => {
  const siteIdFilter = siteId === 'holding' || !siteId ? `` : `siteId == $siteId`;
  const filter = siteIdFilter ? `[${siteIdFilter}]` : '[]';
  const settingsKey = siteId === 'holding' || !siteId ? 'holding-settings' : 'corporate-settings';

  const settingsQuery =
    siteId === 'holding' ? `_type == '${settingsKey}'` : `_type == '${settingsKey}' && siteId == '${siteId}'`;

  return getClient(preview).fetch<SitewideData>(
    groq`*${filter}[0]{
    "navigation": ${getNavigationQuery(siteId)},
    "socials": ${getSocialsQuery(siteId)},
    "popup": ${getPopupQuery(siteId)},
    "mapStyleString": *[${settingsQuery}][0].mapStyleString,
    "leadInfoKey": *[${settingsQuery}][0].leadInfoKey,
    ${createSchemaQuery(siteId)},
  }`,
    { siteId }
  );
};
