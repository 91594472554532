import { groq } from 'next-sanity';
import { contentLinkQuery, linkQueryBody } from '../../queries/helpers/link';

export const NAME = 'testimonialOverImage';

export const testimonialOverImageQuery = groq`
  _type == '${NAME}' => {
    _type,
    text[] ${contentLinkQuery},    
    quoteAuthorName,
    quoteAuthorRole,
    buttons[] {${linkQueryBody}},
    image,
    settings {
      isFullWidth,      
      backgroundColor,
    }
  },
`;

export type TestimonialOverImageBlockLinkProperties = {
  label?: string;
  href?: { _type: Sanity.Schema.Document['_type']; slug?: { _type: 'slug'; current?: string } };
  targetBlank?: boolean;
  externalLink?: string;
  type?: 'external' | 'reference';
};

export type TestimonialOverImageBlock = {
  _type: 'testimonialOverImage';
  text?: Array<Sanity.Keyed<Sanity.Block>>;
  quoteAuthorName?: string;
  quoteAuthorRole?: string;
  image?: Sanity.Image;
  buttons?: Array<TestimonialOverImageBlockLinkProperties>;
  settings?: {
    isFullWidth?: boolean;
    backgroundColor?: 'blue' | 'gray' | 'lightGray' | 'white';
  };
};
