import { groq } from 'next-sanity';
import { contentLinkQuery, linkQueryBody } from '../helpers/link';
import { imageQuery } from '../helpers/image';

export const NAME = 'verticalCarouselWithTitles';

export const verticalCarouselWithTitleQuery = groq`
  _type == '${NAME}' => {
    _type,
    title,
    description[] ${contentLinkQuery},
    carouselItems[] {
      _key,
      buttonLabel,
      imageAndTextItem {
        _key,
        title,
        text[] ${contentLinkQuery},
        button {${linkQueryBody}},
        ${imageQuery},
      }

    },
    backgroundColor,
  },
`;

export interface VerticalCarouselBlock extends Omit<Sanity.Keyed<Sanity.Schema.VerticalCarouselWithTitles>, '_type'> {
  _type: 'verticalCarouselWithTitles';
}
