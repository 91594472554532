import './global.css';

import { AppPageProperties } from '@klokgroep/shared-components/src/PreviewAppComponent';
import { AppProps } from 'next/app';
import { captureException } from '@klokgroep/shared-components/utils/captureException';
import { CookieModal } from '@klokgroep/shared-components/src/CookieModal';
import { createHref, PreviewSuspense, SITES } from '@klokgroep/sanity';
import { NextIntlProvider } from '@klokgroep/shared-components/utils/useTranslations';
import { PreviewBar } from '@klokgroep/shared-components/src/PreviewBar';
import { Schema } from '@klokgroep/shared-components/src/Schema';
import { SiteFooter } from '@klokgroep/shared-components/src/SiteFooter';
import { SiteHeader } from '@klokgroep/shared-components/src/SiteHeader';
import { SiteInfoProvider } from '@klokgroep/shared-components/src/SiteInfoProvider';
import { SitewideDismissablePopup } from '@klokgroep/shared-components/src/SitewideDismissablePopup';
import { useRouter } from 'next/router';
import { useSaveRouterPathToSessionStorageOnRouterPathChange } from '@klokgroep/shared-components/utils/routerSessionStorage';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import React, { useMemo } from 'react';
import styles from './_app.module.css';
import Script from 'next/script';

const PreviewAppComponent = dynamic(() =>
  import('@klokgroep/shared-components/src/PreviewAppComponent').then(({ PreviewAppComponent }) => PreviewAppComponent)
);

const App = ({ Component, pageProps }: AppProps<AppPageProperties>) => {
  useSaveRouterPathToSessionStorageOnRouterPathChange();

  const router = useRouter();

  const siteData = useMemo(() => SITES.find((site) => site.siteId === pageProps.siteId), [pageProps.siteId]);

  const defaultMetaData = useMemo(() => {
    const siteName = siteData?.title || 'KlokGroep';

    return {
      title: siteName,
      description: `${siteName} is een daadkrachtig bedrijf. Dankzij een sterke financiële basis zijn wij samen met onze zusterondernemingen in staat grote huisvestingsvraagstukken doordacht in te vullen en risico's van opdrachtgevers over te nemen.`,
    };
  }, [siteData?.title]);

  const title = useMemo(
    //remove layout characters from page title
    () => (pageProps.metaData?.title || defaultMetaData.title).replaceAll('*', '').replaceAll('--', ''),
    [defaultMetaData.title, pageProps.metaData?.title]
  );

  const description = useMemo(
    () => pageProps.metaData?.description || defaultMetaData.description,
    [defaultMetaData.description, pageProps.metaData?.description]
  );

  const ogImage = useMemo(
    () =>
      pageProps.metaData?.image
        ? `${pageProps.metaData.image}?w=1200&h=630&auto=format&fit=crop&crop=entropy`
        : undefined,
    [pageProps.metaData?.image]
  );

  const firstFooterMenu = useMemo(
    () => ({
      title: pageProps.navigation?.firstFooterMenu.title,
      items: pageProps.navigation?.firstFooterMenu.items?.map((link) => ({
        label: link.label || '',
        href: createHref({ ...link, locale: siteData?.locale }, captureException),
      })),
    }),
    [pageProps.navigation?.firstFooterMenu.items, pageProps.navigation?.firstFooterMenu.title, siteData?.locale]
  );

  const secondFooterMenu = useMemo(
    () => ({
      title: pageProps.navigation?.secondFooterMenu.title,
      items: pageProps.navigation?.secondFooterMenu.items?.map((link) => ({
        label: link.label || '',
        href: createHref({ ...link, locale: siteData?.locale }, captureException),
      })),
    }),
    [pageProps.navigation?.secondFooterMenu.items, pageProps.navigation?.secondFooterMenu.title, siteData?.locale]
  );

  const subMenu = useMemo(
    () =>
      pageProps.navigation?.subFooterMenu.items?.map((link) => ({
        label: link.label || '',
        href: createHref({ ...link, locale: siteData?.locale }, captureException),
      })),
    [pageProps.navigation?.subFooterMenu.items, siteData?.locale]
  );

  const productionHostName = useMemo(
    () => SITES.find((site) => site.siteId === pageProps.siteId)?.productionHostname || '',
    [pageProps.siteId]
  );

  return (
    <NextIntlProvider locale="nl-NL" messages={pageProps.messages}>
      <Head>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        {ogImage ? <meta property="og:image" content={ogImage} /> : undefined}
        <meta property="og:type" content="website" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:creator" content={`@${defaultMetaData.title}`} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        {ogImage ? <meta property="twitter:image" content={ogImage} /> : undefined}

        <meta name="application-name" content={defaultMetaData.title} />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content={defaultMetaData.title} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />

        <link rel="apple-touch-icon" sizes="180x180" href={`/favicons/${siteData?.siteId}/apple-touch-icon.png`} />
        <link rel="icon" type="image/png" sizes="32x32" href={`/favicons/${siteData?.siteId}/favicon-32x32.png`} />
        <link rel="icon" type="image/png" sizes="16x16" href={`/favicons/${siteData?.siteId}/favicon-16x16.png`} />
        <link rel="manifest" href={`/favicons/${siteData?.siteId}/site.webmanifest`} />
        <link rel="mask-icon" href={`/favicons/${siteData?.siteId}/safari-pinned-tab.svg`} color="#001d3a" />
        <link rel="shortcut icon" href={`/favicons/${siteData?.siteId}/favicon.ico`} />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-config" content={`/favicons/${siteData?.siteId}/browserconfig.xml`} />
        <meta name="theme-color" content="#ffffff" />

        {pageProps.metaData?.noIndex ? <meta name="robots" content="noindex" /> : undefined}
      </Head>
      {process.env.NODE_ENV === 'production' && !!process.env.NEXT_PUBLIC_GTM_CONTAINER_ID ? (
        <Script strategy="afterInteractive" id="GTM">{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_CONTAINER_ID}');`}</Script>
      ) : undefined}

      {process.env.NODE_ENV === 'production' && !!pageProps.leadInfoKey ? (
        <Script
          strategy="afterInteractive"
          id="Leadinfo">{`(function(l,e,a,d,i,n,f,o){if(!l[i]){l.GlobalLeadinfoNamespace=l.GlobalLeadinfoNamespace||[];
l.GlobalLeadinfoNamespace.push(i);l[i]=function(){(l[i].q=l[i].q||[]).push(arguments)};l[i].t=l[i].t||n;
l[i].q=l[i].q||[];o=e.createElement(a);f=e.getElementsByTagName(a)[0];o.async=1;o.src=d;f.parentNode.insertBefore(o,f);}
}(window,document,'script','https://cdn.leadinfo.net/ping.js','leadinfo','${pageProps.leadInfoKey}'));`}</Script>
      ) : undefined}

      {pageProps.metaData?.schema ? (
        <Schema
          baseUrl={productionHostName}
          locale="NL-nl"
          organisationContext={{
            name: pageProps.metaData?.schema?.organisation?.name || '',
            legalName: pageProps.metaData?.schema?.organisation?.legalName || '',
            description: pageProps.metaData?.schema?.organisation?.description || '',
            logo: {
              url: pageProps.metaData?.schema?.organisation?.logo?.url || '',
              width: pageProps.metaData?.schema?.organisation?.logo?.width || 0,
              height: pageProps.metaData?.schema?.organisation?.logo?.height || 0,
              caption: pageProps.metaData?.schema?.organisation?.name || '',
            },
            mediaUrls: Object.values(pageProps.metaData?.schema?.socials || {}).filter(Boolean) as string[],
            founderName: pageProps.metaData?.schema?.organisation?.founderName || '',
            foundingDate: pageProps.metaData?.schema?.organisation?.foundingDate || '',
            numberOfEmployees: pageProps.metaData?.schema?.organisation?.numberOfEmployees || 0,
            slogan: pageProps.metaData?.schema?.organisation?.slogan || '',
          }}
          pageContext={{
            title: title,
            description: description,
            type: pageProps.metaData?.schema?.type ?? 'WebPage',
            url: productionHostName,
          }}
        />
      ) : undefined}
      <SiteInfoProvider siteId={pageProps.siteId}>
        {pageProps.popup ? <SitewideDismissablePopup {...pageProps.popup} /> : undefined}
        <div className={styles.container}>
          <SiteHeader
            contactPerson={pageProps.contactPerson}
            navigation={
              router.asPath.includes('/vacatures')
                ? pageProps.navigation?.jobNavigationItems
                : pageProps.navigation?.mainNavigation
            }
          />
          <main className={styles.main}>
            {pageProps.preview && pageProps.previewData ? (
              <PreviewSuspense
                fallback={
                  <div style={{ marginTop: 40, display: 'flex', justifyContent: 'center' }}>Loading preview...</div>
                }>
                <PreviewAppComponent {...pageProps} Component={Component} />
              </PreviewSuspense>
            ) : (
              <Component {...pageProps} />
            )}
          </main>
          <SiteFooter
            firstFooterMenu={firstFooterMenu}
            secondFooterMenu={secondFooterMenu}
            subMenu={subMenu}
            socialLinks={pageProps.socials}
          />
          <CookieModal />
          {pageProps.preview ? <PreviewBar /> : undefined}
        </div>
      </SiteInfoProvider>
    </NextIntlProvider>
  );
};

export default App;
