import { CaptureContext } from '@sentry/types';
import { captureException as sentryCaptureException } from '@sentry/nextjs';

export function captureException(error: any, captureContext?: CaptureContext) {
  if (process.env.NODE_ENV !== 'production') {
    console.error(error, captureContext);
  }

  sentryCaptureException(error, captureContext);
}
