import { groq } from 'next-sanity';
import { SiteIdType } from '../../sites';
import { createSeoQuery, SeoQuery, createSitewideSeoQuery, SitewideSeoQuery } from './seoQuery';
import { createSocialsQuery, SocialsQuery } from './socialsQuery';

export interface SchemaQuery {
  sitewideSeo: SitewideSeoQuery;
  socials: SocialsQuery;
  seo: SeoQuery;
}

export const createSchemaQuery = (siteId?: SiteIdType) => groq`
  "schema": {
    ${createSitewideSeoQuery(siteId)},
    ${createSeoQuery(siteId)},
    ${createSocialsQuery(siteId)},
  }
`;
