import { groq } from 'next-sanity';

export const createHrefQueryWithParents = (): string => groq`
    href->{
      _type,
      slug,
      ${createParentsQuery()}
    },
  `;

export const createParentsQuery = (depth = 2): string => {
  if (depth === 0) {
    return '';
  }
  return groq`
    parent->{
      _type,
      slug,
      "title": pageHero.title,
      ${createParentsQuery(depth - 1)}
    },
  `;
};
