import { groq } from 'next-sanity';
import { SiteIdType } from '../sites';
import { getSitewideDataFilter } from '../utils';

export type SocialsQuery = Sanity.Schema.HoldingSettings['socials'];

export const getSocialsQuery = (siteId?: SiteIdType) => {
  const siteIdFilter =
    siteId === 'holding' || siteId === undefined
      ? `_type == 'holding-settings'`
      : `_type == 'corporate-settings' && siteId == '${siteId}'`;

  const filter = getSitewideDataFilter(siteIdFilter);
  return groq`*${filter}[0].socials`;
};
