import { ReactNode } from 'react';
import styles from './InputContainer.module.css';

export interface InputContainerProperties {
  children: ReactNode;
  description?: string;
  error?: string;
  label?: string;
}

export const InputContainer = ({ children, description, error, label }: InputContainerProperties) => (
  <div className={styles.container}>
    <div className={styles.labelContainer}>{!!label && <label>{label}</label>}</div>
    {!!description && <div className={styles.description}>{description}</div>}
    <div className={styles.childContainer}>{children}</div>
    {!!error && <div className={styles.error}>{error}</div>}
  </div>
);
