import { getClient } from '../sanity.server';
import { groq } from 'next-sanity';
import { imageAndFoldoutsQuery } from './blocks/imageAndFoldouts';
import { PageHeroBlock, pageHeroQuery } from './blocks/pageHero';
import { createSeoQuery, SeoQuery } from './helpers/seoQuery';
import { SiteIdType } from '../sites';
import { getSitewideDataFilter } from '../utils';
import { getCorporateContentModulesQueries } from '../corporateQueries';

export interface HoldingContactDetailsResponse extends Omit<Sanity.Schema.HoldingSettings, 'seo'> {
  _id: string;
}

export interface CorporateContactDetailsResponse extends Omit<HoldingContactDetailsResponse, 'imageAndFoldouts'> {
  visitingAddress2?: {
    street?: string;
    number?: string;
    zipcode?: string;
    city?: string;
  };
  postalAddress2?: {
    mailBox?: string;
    zipcode?: string;
    city?: string;
  };
}

export const createContactDetailsQuery = (siteId?: SiteIdType) => {
  const siteIdFilter =
    siteId === 'holding' || siteId === undefined
      ? `_type == 'holding-settings'`
      : `_type == 'corporate-settings' && _id == 'corporate-settings-${siteId}'`;

  const filter = getSitewideDataFilter(siteIdFilter);

  return groq`*${filter}[0]{
  emailAndPhone,
  postalAddress,
  postalAddress2,
  visitingAddress2,
  visitingAddress
}`;
};

export const getContactDetails = async (preview = false, siteId?: SiteIdType) =>
  getClient(preview).fetch<HoldingContactDetailsResponse>(createContactDetailsQuery(siteId));

export interface ContactPageResponse extends Omit<Sanity.Schema.HoldingContactPage, 'seo' | 'pageHero'> {
  _id: string;
  seo: SeoQuery;
  pageHero: PageHeroBlock;
  contactTypes: Sanity.Schema.CorporateContactPage['contactTypes'];
  contactSettings: Sanity.Schema.HoldingContactPage['contactSettings'];
}

export const createContactPageQuery = (siteId: SiteIdType) => {
  const typeFilter =
    siteId === 'holding' || siteId === undefined
      ? `_type == 'holding-contactPage'`
      : `_type == 'corporate-contactPage' && _id == 'corporate-contactPage-${siteId}'`;

  const filter = `[${typeFilter}]`;

  return groq`*${filter} | order(_updatedAt desc)[0] {
  _id,
  _type,
  contactTypes,
  ${createSeoQuery()},
  ${pageHeroQuery},
  contactSettings,
  corporateContentModules[] { ...select( ${getCorporateContentModulesQueries(siteId)} ), },
  mapZoomLevel,
  geo,
  geo2,
  location,
  location2,
  locationDescription,
  location2Description,
  emailConfirmationTemplateName,
  imageAndFoldouts { 
    ${imageAndFoldoutsQuery}
  }
}`;
};

export const getContactPage = async (preview = false, siteId: SiteIdType) =>
  getClient(preview).fetch<ContactPageResponse>(createContactPageQuery(siteId), { siteId });
