import { groq } from 'next-sanity';
import { createImageTitleAndAltFallbacks } from '../helpers/image';
import { LinkQuery, contentLinkQuery, linkQueryBody } from '../helpers/link';

export const NAME = 'peopleGrid';

export const peopleGridQuery = groq`
  _type == '${NAME}' => {
    _type,
    people[] {
      image {
        ...,
        ${createImageTitleAndAltFallbacks(true)}
      },
      name,
      jobTitle,
      largeImage {
        ...,
        ${createImageTitleAndAltFallbacks(true)}
      },
      content[] ${contentLinkQuery},
      phoneNumber,
      linkedin,
      link { ${linkQueryBody} }
    },
    backgroundColor,
  },
`;

export interface PeopleGridBlock extends Omit<Sanity.Keyed<Sanity.Schema.PeopleGrid>, 'people'> {
  people?: ({
    link?: LinkQuery;
  } & Omit<NonNullable<Sanity.Schema.PeopleGrid['people']>[number], 'link'>)[];
}
