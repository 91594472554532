import { createHref, LinkType } from '@klokgroep/sanity';
import { PortableText, PortableTextProps } from '@portabletext/react';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';
import Link from 'next/link';
import React, { ReactNode } from 'react';

const createComponents = (locale = 'nl') => ({
  marks: {
    sup: ({ children }: { children?: ReactNode }) => <sup>{children}</sup>,
    link: ({ value, children }: { value?: LinkType; children?: ReactNode }) => (
      <Link
        href={createHref({ ...value, locale })}
        target={value?.targetBlank ? '_blank' : undefined}
        rel="noopener noreferrer">
        {children}
      </Link>
    ),
  },
});

interface Properties {
  content: PortableTextProps['value'];
}

// TODO: make this work with siteId
export const PortableTextWithLinks = ({ content }: Properties) => {
  const { locale } = useSiteInfo();
  return <PortableText value={content} components={createComponents(locale)} />;
};
