import { groq } from 'next-sanity';
import { imageQuery } from '../helpers/image';
import { LinkQuery, linkQueryBody } from '../helpers/link';

export const NAME = 'callToAction';

export const callToActionQuery = groq`
  _type == '${NAME}' => {
    _type,
    ${imageQuery},
    buttons[] {${linkQueryBody}},
    text,
    title,
    backgroundColor
  },
`;

export interface CallToActionBlock extends Omit<Sanity.Keyed<Sanity.Schema.CallToAction>, 'buttons'> {
  buttons?: LinkQuery[];
}
