import { groq } from 'next-sanity';
import { LinkType } from '../helpers';
import { imageQuery } from '../helpers/image';
import { contentLinkQuery, linkQueryBody } from '../helpers/link';

const NAME = 'imageAndLinks';

export const imageAndLinksQuery = groq`
  _type == '${NAME}' => {
    _type,
    _key,
    title,
    content[] ${contentLinkQuery},
    backgroundColor,
    reversed,
    video,
    ${imageQuery},
    "items": items[]{ 
      ${linkQueryBody}
    },
  },
`;

export interface ImageAndLinksBlock extends Omit<Sanity.Keyed<Sanity.Schema.ImageAndLinks>, 'items'> {
  items?: {
    link?: LinkType;
  }[];
}
