import { NewsletterForm } from '@klokgroep/shared-components/src/Newsletter';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import { TitleOverTwoRules } from '@klokgroep/shared-components/src/TitleOverTwoRules';
import { SanityImageType } from '@klokgroep/sanity';
import styles from './NewsletterSubscriptionTile.module.css';
import { useCommonTranslations } from '@klokgroep/shared-components/utils/useTranslations';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';
import dynamic from 'next/dynamic';
import { ReactNode, SetStateAction } from 'react';

interface Properties {
  title?: string;
  subtitle?: string;
  imageAsset?: NonNullable<SanityImageType>;
  buttonLabel?: string;
  setSubscriberId?: (value: SetStateAction<undefined>) => void;
}

const DynamicContentCard = dynamic(
  () =>
    import('@klokgroep/shared-components/src/ContentCardBoxedLarge/ContentCardBoxedLarge').then(
      (component) => component.ContentCardBoxedLarge
    ),
  {
    ssr: false,
  }
);

export const NewsletterSubscriptionTile = ({ title, subtitle, imageAsset, setSubscriberId }: Properties) => {
  const { theme } = useSiteInfo();

  const t = useCommonTranslations();

  return (
    <div className={styles.container}>
      {!theme ||
        (theme === 'holding' && (
          <div className={styles.hexagon}>
            <PentagonBackground />
          </div>
        ))}

      <Wrapper image={imageAsset}>
        <RichText>
          <TitleOverTwoRules as="h3">{title || t.raw('newsletter_tile_title')}</TitleOverTwoRules>
          <p>{subtitle || t('newsletter_tile_description')}</p>
        </RichText>
        <div>
          <NewsletterForm setSubscriberId={setSubscriberId} gtmFormId="6" />
        </div>
      </Wrapper>
    </div>
  );
};

const Wrapper = ({ children, image }: { children: ReactNode; image?: NonNullable<SanityImageType> }) => {
  const { theme } = useSiteInfo();
  return !theme || theme === 'holding' ? (
    <div className={styles.wrapper}>{children}</div>
  ) : (
    <DynamicContentCard contentFullWidth smallGap image={image}>
      {children}
    </DynamicContentCard>
  );
};

const PentagonBackground = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="336" height="295" fill="none">
    <g stroke="#75EEA9" opacity=".2">
      <path d="M232.238 233.78v.001L127.846 430.977l-.002.005c-.578 1.124-1.618 2.131-2.852 2.846-1.235.715-2.624 1.113-3.87 1.066l-221.438-8.808h-.001c-2.476-.093-4.816-2.168-5.269-4.653v-.002l-45.429-257.284-.001-.003c-.223-1.229.028-2.59.627-3.808.598-1.217 1.526-2.252 2.604-2.845l.001-.001L57.967 47.198l.004-.001c2.247-1.146 5.454-.598 7.123 1.2L231.263 226.43c.829.894 1.375 2.176 1.558 3.532.183 1.355-.005 2.735-.582 3.819Z" />
      <path d="M250.959 296.236v.001L129.077 526.472l-.003.005c-.683 1.33-1.909 2.516-3.362 3.357-1.452.841-3.088 1.312-4.562 1.257l-.002-.001-258.538-10.284h-.001c-2.931-.11-5.696-2.563-6.231-5.502v-.001l-53.041-300.392v-.002c-.265-1.456.033-3.064.738-4.498.706-1.435 1.799-2.656 3.076-3.359l.001-.001L47.378 78.28l.003-.002c2.656-1.355 6.442-.709 8.417 1.419h.001l194.008 207.86c.982 1.059 1.626 2.573 1.841 4.169.215 1.597-.005 3.226-.689 4.51Z" />
      <path d="M195.34 164.418v.001L73.458 394.654l-.003.005c-.683 1.33-1.91 2.516-3.362 3.357-1.452.841-3.088 1.312-4.563 1.256l-258.539-10.284h-.001c-2.931-.11-5.696-2.563-6.231-5.502l-.001-.001-53.04-300.392v-.002c-.265-1.456.033-3.064.738-4.499.705-1.434 1.799-2.655 3.076-3.358h.001L-8.241-53.539l.003-.002c2.656-1.355 6.442-.709 8.417 1.419l194.008 207.86c.983 1.059 1.627 2.573 1.842 4.169.215 1.597-.005 3.226-.689 4.51Z" />
      <path d="M245.953 156.075v.001L124.071 386.311l-.002.005c-.684 1.33-1.91 2.516-3.363 3.357-1.452.841-3.088 1.311-4.562 1.256h-.001l-258.539-10.284h-.001c-2.931-.11-5.696-2.563-6.231-5.502v-.001l-53.04-300.392-.001-.002c-.265-1.457.033-3.064.738-4.499.706-1.434 1.8-2.655 3.076-3.358h.001L42.372-61.882l.004-.002c2.655-1.355 6.442-.709 8.416 1.419h.001l194.008 207.86c.983 1.059 1.626 2.573 1.841 4.169.215 1.597-.005 3.226-.689 4.51Z" />
      <path d="m301.018 160.525-.001.001L179.136 390.76l-.003.006c-.683 1.329-1.91 2.516-3.362 3.357-1.452.84-3.089 1.311-4.563 1.256h-.001l-258.538-10.285h-.001c-2.931-.11-5.696-2.562-6.232-5.501v-.002L-146.604 79.2l-.001-.003c-.264-1.456.033-3.064.739-4.498.705-1.434 1.799-2.656 3.076-3.358l.001-.001L97.436-57.432a.113.113 0 0 1 .004-.002c2.656-1.354 6.442-.708 8.417 1.42h.001l194.007 207.86c.983 1.058 1.626 2.572 1.841 4.169.215 1.596-.005 3.226-.688 4.51Z" />
    </g>
  </svg>
);
