import { groq } from 'next-sanity';
import { linkQueryBody } from '../helpers/link';
import { imageQuery } from '../helpers/image';
import { LinkType } from '../helpers';

const NAME = 'portraitSlider';

export const portraitSliderQuery = groq`
  _type == '${NAME}' => {
    _type,
    _key,
    backgroundColor,
    title,
    "items": items[]{
      label,
      title,
      ${imageQuery},
      titleLink,
      ${linkQueryBody}
    },
  },
`;

export interface PortraitSliderBlock extends Omit<Sanity.Keyed<Sanity.Schema.PortraitSlider>, 'items'> {
  items?: LinkType &
    {
      image?: NonNullable<Sanity.Schema.PortraitSlider['items']>[number]['image'];
      title?: string;
      titleLink?: boolean;
    }[];
}
