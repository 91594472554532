import { ButtonLink } from '@klokgroep/shared-components/src/Button';
import { createHref, LinkType, SanityRichTextType } from '@klokgroep/sanity';
import { Modal } from '@klokgroep/shared-components/src/Modal';
import { PortableTextWithLinks } from '@klokgroep/shared-components/src/PortableTextWithLinks';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';
import styles from './SitewideDismissablePopup.module.css';

interface Properties {
  show?: boolean;
  hideInterval?: number;
  content?: SanityRichTextType;
  link?: LinkType;
}

interface LocalStorage {
  hideUntil: string;
  content: SanityRichTextType;
}

// From: https://www.sanity.io/docs/presenting-block-text#ac67a867dd69
function toPlainText(blocks: SanityRichTextType = []) {
  return (
    blocks
      // loop through each block
      .map((block) => {
        // if it's not a text block with children,
        // return nothing
        if (block._type !== 'block' || !block.children) {
          return '';
        }
        // loop through the children spans, and join the
        // text strings
        return block.children.map((child: any) => child.text).join('');
      })
      // join the paragraphs leaving split by two linebreaks
      .join('\n\n')
  );
}

const LOCAL_STORAGE_KEY = 'SitewideDismissablePopup';

export const SitewideDismissablePopup = ({ show, hideInterval = 1, content, link }: Properties) => {
  const { locale } = useSiteInfo();
  const router = useRouter();

  const [shouldShow, setShouldShow] = useState(false);

  const onClose = useCallback(() => {
    const date = new Date();
    date.setDate(date.getDate() + hideInterval);
    localStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify({
        hideUntil: date.toISOString(),
        content,
      })
    );
    setShouldShow(false);
  }, [content, hideInterval]);

  useEffect(() => {
    if (!show || !content) {
      return;
    }

    const localStorageData = localStorage.getItem(LOCAL_STORAGE_KEY);

    if (!localStorageData) {
      return setShouldShow(true);
    }

    const parsed: LocalStorage = JSON.parse(localStorageData);

    if (new Date() > new Date(parsed.hideUntil) || toPlainText(content) !== toPlainText(parsed.content)) {
      setShouldShow(true);
    }
  }, [content, show]);

  useEffect(() => {
    if (!shouldShow) {
      return;
    }

    router.events.on('routeChangeComplete', onClose);
    return () => router.events.off('routeChangeComplete', onClose);
  });

  if (!shouldShow) {
    return null;
  }

  return (
    <Modal onClose={onClose}>
      <RichText large>{content ? <PortableTextWithLinks content={content} /> : undefined}</RichText>
      {link?.href && link?.label ? (
        <div className={styles.buttonContainer}>
          <ButtonLink onClick={onClose} href={createHref({ ...link, locale })}>
            {link?.label}
          </ButtonLink>
        </div>
      ) : undefined}
    </Modal>
  );
};
