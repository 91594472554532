const PAGES_PATHS_TRANSLATIONS = {
  de: {
    kontakt: 'contact',
    nachricht: 'nieuws',
    projekte: 'projecten',
    stellenangebote: 'vacatures',
    suchen: 'zoeken',
  },
  pl: {
    kontakt: 'contact',
    aktualnosci: 'nieuws',
    projekty: 'projecten',
    wakaty: 'vacatures',
    szukac: 'zoeken',
  },
};

/*
  This function takes a pathname and locale and checks if the pathname includes a part that is translated.
  This is used to check when a user navigates to a page that is translated, so we can show a 404 when they try to access the original.
  ie; <deutschesite>/contact should not be accessible, since <deutschesite>/contact is translated to <deutschesite>/kontakt
*/
export function pathIncludesTranslationalPart(pathName: string, locale = 'nl') {
  // We dont mind the any here since we check if it is undefined in the next line
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const translationsForLocale = PAGES_PATHS_TRANSLATIONS[locale];

  if (!translationsForLocale) {
    return false;
  }

  return pathName.split('/').some((urlPart) => Object.values(translationsForLocale).includes(urlPart));
}

/*
  This function takes a pathname and locale and translates it to the original pages path.
  This is used in the middleware to make sure that when a user navigates to a translated page, they are masked to the original.
  ie; <deutschesite>/kontakt should be masked to <deutschesite>/contact
*/
export function translatePathnameToPagesPath(pathName: string, locale = 'nl') {
  // We dont mind the any here since we check if it is undefined in the next line
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const translationsForLocale = PAGES_PATHS_TRANSLATIONS[locale];

  if (!translationsForLocale) {
    return pathName;
  }

  return pathName
    .split('/')
    .map((urlPart) => translationsForLocale[urlPart] || urlPart)
    .join('/');
}

/*
  This function takes a orginal pages pathname and locale and translates it to the pathnames of the translated pages.
  This is used in hrefs (createHref.ts) to make sure that the urls are translated to the correct locale.
  ie; <deutschesite>/contact should be translated to <deutschesite>/kontakt.
*/
export function translatePagesPathToPathname(pagesPathName: string, locale = 'nl') {
  // We dont mind the any here since we check if it is undefined in the next line
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const translationsForLocale = PAGES_PATHS_TRANSLATIONS[locale];

  if (!translationsForLocale) {
    return pagesPathName;
  }

  return pagesPathName
    .split('/')
    .map((urlPart) => {
      const foundTranslationForUrlPart = Object.entries(translationsForLocale).find(
        ([, orginale]) => urlPart === orginale
      );
      return foundTranslationForUrlPart && foundTranslationForUrlPart.length > 0
        ? foundTranslationForUrlPart[0]
        : urlPart;
    })
    .join('/');
}
