import { groq } from 'next-sanity';
import { contentLinkQuery } from '../helpers/link';
import { BlockContainerWithBackgroundProperties } from '@klokgroep/shared-components/src/BlockContainerWithBackground';

export const NAME = 'formBlock';

export const formBlockQuery = groq`
  _type == '${NAME}' => {
  _type,
  title,
  description[] ${contentLinkQuery},
  tags,
  inboundEmail,
  outboundEmail,
  ccInboundEmails,
  mailchimpListId,
  emailConfirmationTemplateName
  },
`;

export interface FormBlockProperties extends Omit<Sanity.Keyed<Sanity.Schema.FormBlock>, 'backgroundColor'> {
  backgroundColor?: BlockContainerWithBackgroundProperties['backgroundColor'];
}
