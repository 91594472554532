import { groq } from 'next-sanity';
import { imageQuery } from '../helpers/image';
import { contentLinkQuery, linkQueryBody } from '../helpers/link';

const NAME = 'imageAndButton';

export const imageAndButtonQuery = groq`
  _type == '${NAME}' => {
    ...,
    content[] ${contentLinkQuery},
    video,
    ${imageQuery},
    button {
      ${linkQueryBody}
    }
  },
`;

export interface ImageAndButtonBlock extends Omit<Sanity.Keyed<Sanity.Schema.ImageAndButton>, 'button'> {
  button?: {
    label?: string;
    href?: { _type: Sanity.Schema.Document['_type']; slug?: { _type: 'slug'; current?: string } };
  };
}
