import { groq } from 'next-sanity';
import { contentLinkQuery } from '../helpers/link';

const NAME = 'richText';

export const richTextQuery = groq`
  _type == '${NAME}' => {
    _type,
    _key,
    backgroundColor,
    content[] ${contentLinkQuery},
  },
`;

export type RichTextBlock = Sanity.Schema.RichText;
