import { CallToActionBlock, callToActionQuery } from '../../queries/blocks/callToAction';
import { ImageAndButtonBlock, imageAndButtonQuery } from '../../queries/blocks/imageAndButton';
import { ImageAndFoldoutsBlock, imageAndFoldoutsModuleQuery } from '../../queries/blocks/imageAndFoldouts';
import { ImageAndLinksBlock, imageAndLinksQuery } from '../../queries/blocks/imageAndLinks';
import { ImageAndTextBlock, imageAndTextQuery } from '../../queries/blocks/imageAndText';
import { ImagesWithLinksBlock, imagesWithLinksModuleQuery } from '../../queries/blocks/imagesWithLinks';
import { mediaQuery, MediaBlock } from './mediaBlock';
import { RichTextBlock, richTextQuery } from '../../queries/blocks/richText';
import { TestimonialOverImageBlock, testimonialOverImageQuery } from './testimonialBlock';
import { TextAndFoldoutsBlock, textAndFoldoutsModuleQuery } from '../../queries/blocks/textAndFoldouts';
import { textOverImageQuery, TextOverImageBlock } from './textOverImage';
import { TextWithContentCardsBlock, textWithContentCardsQuery } from './textWithContentCards';
import {
  TitleMultipleRichTextBlock,
  titleMultipleRichTextQuery,
} from '../../corporateQueries/blocks/titleMultipleRichText';
import { TwoColumnsBlock, twoColumnsQuery } from '../../corporateQueries/blocks/twoColumns';
import { UspWithLogoAndTextBlock, uspWithLogoAndTextQuery } from '../../corporateQueries/blocks/uspWithLogoAndText';
import { blockWithColumnsAndIconsQuery, ColumnsAndIconsBlock } from '../../queries';
import { RelatedContentBlockProperties, getRelatedContentQuery } from '../../queries/blocks/relatedContent';
import { SiteIdType } from '../../sites';
import { VerticalCarouselBlock, verticalCarouselWithTitleQuery } from '../../queries/blocks/verticalCarouselWithTitles';
import { FormBlockProperties, formBlockQuery } from '../../queries/blocks/formBlock';
import { PeopleGridBlock, peopleGridQuery } from '../../queries/blocks/peopleGrid';
import {
  XlImageWithRichTextAndButtonBlock,
  xlImageWithRichTextAndButtonQuery,
} from '../../queries/blocks/xlImageWithRichTextAndButton';
import { EmbedBlockProperties, embedQuery } from '../../queries/blocks/embed';
import {
  ImageAndNewsletterSignUpBlock,
  imageAndNewsletterSignupQuery,
} from '../../queries/blocks/imageAndNewsletterSignup';

export const getCorporateContentModulesQueries = (siteId: SiteIdType, preview?: boolean) =>
  [
    blockWithColumnsAndIconsQuery,
    callToActionQuery,
    embedQuery,
    formBlockQuery,
    getRelatedContentQuery(siteId, preview),
    imageAndButtonQuery,
    imageAndFoldoutsModuleQuery,
    imageAndLinksQuery,
    imageAndNewsletterSignupQuery,
    imageAndTextQuery,
    imagesWithLinksModuleQuery,
    mediaQuery,
    peopleGridQuery,
    richTextQuery,
    testimonialOverImageQuery,
    textAndFoldoutsModuleQuery,
    textOverImageQuery,
    xlImageWithRichTextAndButtonQuery,
    textWithContentCardsQuery,
    titleMultipleRichTextQuery,
    twoColumnsQuery,
    uspWithLogoAndTextQuery,
    verticalCarouselWithTitleQuery,
  ].join('');

export type MappedCorporateContentModules = Array<
  | ColumnsAndIconsBlock
  | CallToActionBlock
  | EmbedBlockProperties
  | FormBlockProperties
  | ImageAndButtonBlock
  | ImageAndFoldoutsBlock
  | ImageAndLinksBlock
  | ImageAndNewsletterSignUpBlock
  | ImageAndTextBlock
  | ImagesWithLinksBlock
  | XlImageWithRichTextAndButtonBlock
  | MediaBlock
  | PeopleGridBlock
  | RelatedContentBlockProperties
  | RichTextBlock
  | TestimonialOverImageBlock
  | TextAndFoldoutsBlock
  | TextOverImageBlock
  | TextWithContentCardsBlock
  | TitleMultipleRichTextBlock
  | TwoColumnsBlock
  | UspWithLogoAndTextBlock
  | VerticalCarouselBlock
>;
