import { SiteIdType } from '../sites';
import { getClient } from '../sanity.server';
import { groq } from 'next-sanity';
import { SeoQuery, createSeoQuery } from '../queries/helpers/seoQuery';
import { createParentsQuery } from '../queries/helpers/createHrefQueryWithParents';
import { LinkType, SanityImageType } from '../queries/helpers';

export interface GetSharedSearchPageQueryResult {
  title?: string;
  seo?: SeoQuery;
  searchResults?: SearchResult[];
  searchResultsNews?: SearchResult[];
  searchResultsProjects?: SearchResultProject[];
}

export interface SearchResult {
  _id: string;
  _type: string;
  title: string;
  intro: string;
  link: LinkType;
}
export interface SearchResultProject extends SearchResult {
  image?: SanityImageType;
  location: string;
}

const SEARCH_QUERY = groq`(
  pageHero.title match $searchTerm + '*' ||
  pt::text(pageHero.richText) match $searchTerm + '*' ||
  pt::text(pageHero.intro) match $searchTerm + '*' ||
  
  jobHero.title match $searchTerm + '*' ||
  jobHero.intro match $searchTerm + '*' ||

  pt::text(corporateProjectHero.intro) match $searchTerm + '*' ||
  
  blogHero.title match $searchTerm + '*' ||
  blogHero.intro match $searchTerm + '*' ||

  title match $searchTerm + '*' ||
  seo.title match $searchTerm + '*' ||
  previewText match $searchTerm + '*' ||
  
  corporateContentModules[].title match "*" + $searchTerm + '*' ||
  corporateContentModules[].text match "*" + $searchTerm + '*' ||
  corporateContentModules[].quoteAuthorName match "*" + $searchTerm + '*' ||
  corporateContentModules[].quoteAuthorRole match "*" + $searchTerm + '*' ||
  corporateContentModules[].items[].title match "*" + $searchTerm + '*' ||
  corporateContentModules[].items[].label match "*" + $searchTerm + '*' ||
  corporateContentModules[].contentCards[].link.label match "*" + $searchTerm + '*' ||
  corporateContentModules[].contentCards[].title match "*" + $searchTerm + '*' ||
  corporateContentModules[].contentCards[].subText match "*" + $searchTerm + '*' ||
  pt::text(corporateContentModules[].items[].content) match "*" + $searchTerm + '*' ||
  pt::text(corporateContentModules[].text) match "*" + $searchTerm + '*' ||
  pt::text(corporateContentModules[].richTextModules[].content) match "*" + $searchTerm + '*' ||
  pt::text(corporateContentModules[].subText) match "*" + $searchTerm + '*' ||
  pt::text(corporateContentModules[].columnOne.content) match "*" + $searchTerm + '*' ||
  pt::text(corporateContentModules[].columnTwo.content) match "*" + $searchTerm + '*' ||
  pt::text(corporateContentModules[].content) match "*" + $searchTerm + '*'
)`;

const EXCLUDE_NO_INDEX_QUERY = `seo.noIndex != true && parent->seo.noIndex != true`;

export const createSharedSearchPageQuery = (
  siteId?: SiteIdType
) => groq`*[_type == 'corporate-search-page' && siteId == $siteId][0] {
  ...,
  ${createSeoQuery(siteId)},
  "searchResults": *[${
    siteId === 'holding' ? '_type == "holding-page"' : 'siteId in $siteIds'
  } && ( defined(pageHero.title) || defined(jobHero.title) ) &&  ${SEARCH_QUERY} && ${EXCLUDE_NO_INDEX_QUERY}] {
    _id,
    _type,
    "title": coalesce(blogHero.title, pageHero.title, jobHero.title),
    "intro": previewText,
    "link": {
      "type": "reference",
      "href": {
        slug,
        _type,
        ${createParentsQuery()}
      }
    },
  },
  "searchResultsNews": *[siteId in $siteIds && ( defined(blogHero.title) ) && ${SEARCH_QUERY} && ${EXCLUDE_NO_INDEX_QUERY}] {
    _id,
    _type,
    "title": blogHero.title,
    "intro": previewText,
    "link": {
      "type": "reference",
      "href": {
        slug,
        _type,
        ${createParentsQuery()}
      }
    },
  },
  "searchResultsProjects": *[${
    siteId === 'holding' ? 'showOnHolding == true &&' : `siteId in $siteIds &&`
  } _type == 'corporate-project' && ${SEARCH_QUERY} && ${EXCLUDE_NO_INDEX_QUERY}] {
    _id,
    _type,
    title,
    "intro": corporateProjectHero.intro[0].children[0].text,
    "image": corporateProjectHero.images[0],    
    location,
    "link": {
      "type": "reference",
      "href": {
        slug,
        _type,
        ${createParentsQuery()}
      }
    },
  }
}`;

export const getSharedSearchPage = async (
  preview = false,
  siteId: SiteIdType,
  searchSiteIds: string[],
  searchTerm: string
) =>
  getClient(preview).fetch<GetSharedSearchPageQueryResult>(createSharedSearchPageQuery(siteId), {
    siteId,
    siteIds: searchSiteIds,
    searchTerm,
  });
