import { ReactNode, useMemo } from 'react';
import cx from 'classnames';
import styles from './MaxWidth.module.css';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';

interface Properties {
  children: ReactNode;
  noMobilePadding?: boolean;
  noPadding?: boolean;
  size?: 'small' | 'medium' | 'mediumLarge' | 'extraLarge';
  noMarginOverflow?: boolean;
}

export const MaxWidth = ({
  children,
  noMobilePadding,
  noPadding,
  size,
  noMarginOverflow = false,
}: Properties): JSX.Element => {
  const { theme } = useSiteInfo();
  const hasMarginOverflow = useMemo(() => theme === 'heilijgers', [theme]);
  const className = cx(styles.container, {
    [styles.small]: size === 'small',
    [styles.medium]: size === 'medium',
    [styles.mediumLarge]: size === 'mediumLarge',
    [styles.extraLarge]: size === 'extraLarge',
    [styles.noMobilePadding]: noMobilePadding,
    [styles.noPadding]: noPadding,
    [styles.hasMarginOverflow]: hasMarginOverflow,
    [styles.noMarginOverflow]: noMarginOverflow && hasMarginOverflow,
  });
  return <div className={className}>{children}</div>;
};
