import { Button } from '@klokgroep/shared-components/src/Button';
import { ChangeEventHandler, Fragment, InputHTMLAttributes, useCallback, useEffect, useState } from 'react';
import { COOKIE_LEVELS, getCookiesConsent, setCookiesConsent } from '@klokgroep/shared-components/utils/cookies';
import { Modal } from '@klokgroep/shared-components/src/Modal';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import { ROUTES } from '@klokgroep/shared-components/utils/ROUTES';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';
import Link from 'next/link';
import { useCommonTranslations } from '@klokgroep/shared-components/utils/useTranslations';
import styles from './CookieModal.module.css';

export const CookieModal = () => {
  const { theme } = useSiteInfo();
  const t = useCommonTranslations();

  const [cookieBannerVisible, setCookieBannerVisible] = useState(false);
  const [cookieModalVisible, setCookieModalVisible] = useState(false);

  useEffect(() => {
    const currentConsent = getCookiesConsent();
    setCookieBannerVisible(!currentConsent);
  }, []);

  const [cookiesConsentLevel, setCookiesConsentLevel] = useState<number>(
    getCookiesConsent() || COOKIE_LEVELS.MARKETING
  );

  const onAnalyticsChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setCookiesConsentLevel(event.target.checked ? COOKIE_LEVELS.ANALYTICS : COOKIE_LEVELS.NECESSARY);
  }, []);

  const onMarketingChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setCookiesConsentLevel(event.target.checked ? COOKIE_LEVELS.MARKETING : COOKIE_LEVELS.ANALYTICS);
  }, []);

  const openModal = useCallback(() => setCookieModalVisible(true), []);
  const closeModal = useCallback(() => setCookieModalVisible(false), []);

  const onAcceptAll = useCallback(() => {
    setCookiesConsent(COOKIE_LEVELS.MARKETING);
    setCookieBannerVisible(false);
    closeModal();
  }, [closeModal]);

  const onAcceptSelected = useCallback(() => {
    setCookiesConsent(cookiesConsentLevel || COOKIE_LEVELS.MARKETING);
    setCookieBannerVisible(false);
    closeModal();
  }, [closeModal, cookiesConsentLevel]);

  return (
    <Fragment>
      {cookieBannerVisible ? (
        <div className={styles.banner}>
          <div className={styles.content}>
            <RichText backgroundColor="white">
              {t('cookie_bar_text_first')}{' '}
              <a href="#" onClick={openModal}>
                {t('cookie_bar_text_settings')}
              </a>{' '}
              {t('cookie_bar_text_second')} <Link href={ROUTES.privacy}>{t('cookie_bar_text_privacy_statement')}</Link>.
            </RichText>
          </div>
          <div className={styles.buttonContainer}>
            <div className={styles.outlineButtonContainer}>
              <Button onClick={openModal} theme={theme === 'novaform' ? 'outlineDark' : 'outline'}>
                {t('cookie_button_edit')}
              </Button>
            </div>
            <Button theme={theme === 'novaform' ? 'redText' : undefined} onClick={onAcceptAll}>
              {t('cookie_button_accept')}
            </Button>
          </div>
        </div>
      ) : undefined}
      {cookieModalVisible ? (
        <Modal onClose={closeModal}>
          <RichText>
            <h3>{t('cookie_modal_title')}</h3>
            <p>{t('cookie_modal_subtitle')}</p>
            <p>{t('cookie_modal_text')}</p>
            <p>
              {t('cookie_modal_read_more')} <Link href={ROUTES.privacy}>{t('cookie_bar_text_privacy_statement')}</Link>.
            </p>
          </RichText>

          <div className={styles.inputsContainer}>
            <CookieInput label={t('cookie_modal_level_necessary')} checked disabled readOnly />
            <CookieInput
              label={t('cookie_modal_level_analytics')}
              checked={cookiesConsentLevel > COOKIE_LEVELS.NECESSARY}
              onChange={onAnalyticsChange}
            />
            <CookieInput
              label={t('cookie_modal_level_marketing')}
              checked={cookiesConsentLevel === COOKIE_LEVELS.MARKETING}
              onChange={onMarketingChange}
            />
          </div>

          <div className={styles.buttonContainer}>
            <Button theme={theme === 'novaform' ? 'redText' : undefined} onClick={onAcceptAll}>
              {t('cookie_button_accept')}
            </Button>
            <div className={styles.outlineButtonContainer}>
              <Button
                onClick={onAcceptSelected}
                theme={theme === 'novaform' ? 'outlineDark' : theme === 'vanerk' ? 'outlineInverted' : 'outline'}>
                {t('cookie_button_accept_selected')}
              </Button>
            </div>
          </div>
        </Modal>
      ) : undefined}
    </Fragment>
  );
};

interface CookieInputProperties extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

const CookieInput = ({ label, ...rest }: CookieInputProperties) => (
  <label className={styles.input}>
    <input type="checkbox" {...rest} />
    <span>{label}</span>
  </label>
);
