import { Facebook, Instagram, LinkedIn, Twitter, Youtube } from '@klokgroep/shared-components/src/Icons';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { getSiteLogo } from '@klokgroep/shared-components/src/Logos';
import { MaxWidth } from '@klokgroep/shared-components/src/MaxWidth';
import { NewsletterAdditionalDataPopup, NewsletterForm } from '@klokgroep/shared-components/src/Newsletter';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import { useCommonTranslations } from '@klokgroep/shared-components/utils/useTranslations';
import { useRouter } from 'next/router';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';
import cx from 'classnames';
import Link from 'next/link';
import styles from './SiteFooter.module.css';

interface FooterMenu {
  title?: string;
  items?: { label: string; href: string }[];
}

interface Properties {
  backgroundColor?: 'lightGray' | 'white' | 'blue' | 'gray' | 'dark';
  firstFooterMenu: FooterMenu;
  secondFooterMenu: FooterMenu;
  subMenu: FooterMenu['items'];
  socialLinks?: {
    facebook?: string;
    instagram?: string;
    linkedin?: string;
    twitter?: string;
    youtube?: string;
  };
}

export const SiteFooter = ({
  firstFooterMenu,
  secondFooterMenu,
  subMenu,
  socialLinks,
  backgroundColor,
}: Properties) => {
  const { theme, siteId } = useSiteInfo();
  const router = useRouter();
  const t = useCommonTranslations();

  const SiteLogo = getSiteLogo(siteId || 'holding', true);

  const backgroundToUse = useMemo(() => {
    if (backgroundColor) return backgroundColor;
    if (theme === 'holding') return backgroundColor || 'gray';
    if (theme === 'vandeklok') return backgroundColor || 'blue';
    if (theme === 'novaform') return backgroundColor || 'dark';
    if (theme === 'heilijgers') return backgroundColor || 'darkGray';
    if (theme === 'vanerk') return backgroundColor || 'lightGray';
  }, [backgroundColor, theme]);

  return (
    <footer className={styles.container}>
      <div
        className={cx(styles.background, {
          [styles.white]: backgroundToUse === 'white',
          [styles.blue]: backgroundToUse === 'blue',
          [styles.lightGray]: backgroundToUse === 'lightGray',
          [styles.darkGray]: backgroundToUse === 'darkGray',
          [styles.dark]: backgroundToUse === 'dark',
        })}>
        {!theme || (theme === 'holding' && <FooterBackgroundShape />)}
      </div>
      <MaxWidth noMarginOverflow>
        <div className={styles.firstFooter}>
          {!theme || theme === 'holding' ? (
            // eslint-disable-next-line react/no-danger
            <div className={styles.payoff} dangerouslySetInnerHTML={{ __html: t.raw('footer_slogan_holding') }} />
          ) : undefined}
          <div className={styles.menusContainer}>
            <SiteFooterMenu title={firstFooterMenu.title} items={firstFooterMenu.items} />
            <SiteFooterMenu title={secondFooterMenu.title} items={secondFooterMenu.items} />
          </div>
          <div>
            {!!theme && theme !== 'holding' ? (
              <Fragment>
                <SiteFooterMenu title={t('footer_title')} items={undefined} />
                <SocialIcons
                  items={[
                    { href: socialLinks?.facebook, Icon: Facebook },
                    { href: socialLinks?.twitter, Icon: Twitter },
                    { href: socialLinks?.linkedin, Icon: LinkedIn },
                    { href: socialLinks?.youtube, Icon: Youtube },
                    { href: socialLinks?.instagram, Icon: Instagram },
                  ].filter((item): item is { href: string; Icon: () => JSX.Element } => !!item.href)}
                />
              </Fragment>
            ) : undefined}
            {router.asPath.includes('/vacatures') ? <FormSection type="jobNews" /> : <FormSection type="newsletter" />}
          </div>
        </div>
        <div className={styles.realFooter}>
          <div>
            {!theme || theme === 'holding' ? (
              <SocialIcons
                items={[
                  { href: socialLinks?.facebook, Icon: Facebook },
                  { href: socialLinks?.twitter, Icon: Twitter },
                  { href: socialLinks?.linkedin, Icon: LinkedIn },
                  { href: socialLinks?.youtube, Icon: Youtube },
                ].filter((item): item is { href: string; Icon: () => JSX.Element } => !!item.href)}
              />
            ) : undefined}
          </div>
        </div>
        <div className={styles.bottomContainer}>
          <SubMenu items={subMenu} />
          <div className={styles.logo}>
            <SiteLogo />
          </div>
        </div>
      </MaxWidth>
    </footer>
  );
};

interface FormSectionProperties {
  type?: 'newsletter' | 'jobNews';
}

const FormSection = ({ type = 'newsletter' }: FormSectionProperties) => {
  const t = useCommonTranslations();

  const [subscriberId, setSubscriberId] = useState();

  const title = useMemo(
    () => (type === 'jobNews' ? t('jobs_newsletter_footer_title') : t('newsletter_footer_title')),
    [t, type]
  );

  const description = useMemo(
    () => (type === 'jobNews' ? t('jobs_newsletter_footer_description') : t('newsletter_footer_description')),
    [t, type]
  );

  const onClose = useCallback(() => {
    setSubscriberId(undefined);
  }, []);

  return (
    <div className={styles.formSection}>
      <RichText>
        <h3>
          <strong>{title}</strong>
        </h3>
        <p>{description}</p>
      </RichText>
      <NewsletterForm setSubscriberId={setSubscriberId} type={type} gtmFormId="5" />
      {subscriberId ? (
        <NewsletterAdditionalDataPopup subscriberId={subscriberId} onClose={onClose} type={type} />
      ) : undefined}
    </div>
  );
};

const SocialIcons = ({ items }: { items: { href: string; Icon: () => JSX.Element }[] }) => (
  <div className={styles.socialIcons}>
    {items.map(({ href, Icon }) => (
      <Link className={styles.socialIcon} key={href} href={href} target="_blank" rel="noopener noreferrer">
        <Icon />
      </Link>
    ))}
  </div>
);

const SubMenu = ({ items }: { items?: { href: string; label: string }[] }) => (
  <ul className={styles.subMenu}>
    <li>
      <strong>&copy; KlokGroep</strong>
    </li>
    {items?.map(({ href, label }) => (
      <li key={label}>
        <Link href={href}>{label}</Link>
      </li>
    ))}
  </ul>
);

const SiteFooterMenu = ({ title, items }: { title?: string; items?: { label: string; href: string }[] }) => (
  <div>
    {title ? (
      <RichText>
        <h3 className={styles.siteFooterTitle}>
          <strong>{title}</strong>
        </h3>
      </RichText>
    ) : (
      <div className={styles.siteFooterTitleFiller} />
    )}
    <ul className={styles.linkList}>
      {items?.map(({ label, href }) => (
        <li key={label}>
          <Link className={styles.link} href={href}>
            {label}
          </Link>
        </li>
      ))}
    </ul>
  </div>
);

const FooterBackgroundShape = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="#001D3A" viewBox="0 0 1920 531">
    <path d="M1923.26 1345.37c11.82-9.25 17.33-28.48 12.48-42.59l-443-1278.573C1487.9 10.1 1471.68-.664 1456.49.145L164.105 94.575c-14.934 1.255-30.237 13.707-33.826 28.557L-217.745 1530.84c-3.59 14.85 4.186 32 17.756 38.66l1104.08 545.15c13.57 6.66 33.957 4.61 45.878-4.98l973.291-764.3Z" />
  </svg>
);
