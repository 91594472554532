import React, { useCallback, useState } from 'react';
import styles from './ToggleButton.module.css';

interface Properties {
  externalOnChange: () => void;
  label: string;
}

export const ToggleButton = ({ externalOnChange, label }: Properties) => {
  const [isToggled, setIsToggled] = useState(false);

  const onChange = useCallback(() => {
    setIsToggled((previousState) => !previousState);
    externalOnChange();
  }, [externalOnChange]);

  return (
    <div className={styles.container}>
      <label className={styles.switch}>
        <input type="checkbox" checked={!isToggled} onChange={onChange} />
        <span className={styles.slider} />
      </label>
      <span className={styles.label}>{label}</span>
    </div>
  );
};
