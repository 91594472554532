export const Chevron = () => (
  <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 14.815L7.5 10L7.5 13.185L14 18L20.5 13.185L20.5 10L14 14.815Z" />
  </svg>
);

export const Pentagon = () => (
  <svg viewBox="0 0 36 36" fill="#75EEA9" xmlns="http://www.w3.org/2000/svg">
    <path d="M35.1295 17.7748L23.3578 1.20729C22.8482 0.490049 21.9297 0.190101 21.095 0.468335L1.39718 7.03427C0.56622 7.31126 0.012178 8.09672 0.0300499 8.97245L0.466643 30.3655C0.486052 31.3165 1.17259 32.1223 2.10846 32.2924L21.3211 35.7857C22.0469 35.9176 22.7864 35.6394 23.2451 35.0617L35.0653 20.177C35.619 19.4798 35.6451 18.5006 35.1295 17.7748Z" />
  </svg>
);

export const Facebook = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40">
    <path
      fill="#242628"
      d="m25 21.3.6-3.6H22v-2.4c0-1 .5-2 2-2h1.6v-3S24.3 10 23 10c-2.8 0-4.7 1.8-4.7 4.9v2.8H15v3.6h3.2V30h4v-8.8H25Z"
    />
  </svg>
);

const Globe = (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
    <path
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M10 19a9 9 0 0 0 8.7-6.7M10 19a9 9 0 0 1-8.7-6.7M10 19c2.5 0 4.5-4 4.5-9s-2-9-4.5-9m0 18c-2.5 0-4.5-4-4.5-9s2-9 4.5-9m0 0a9 9 0 0 1 7.8 4.6M10 1a9 9 0 0 0-7.8 4.6m15.6 0a12 12 0 0 1-15.6 0m15.6 0a9 9 0 0 1 1 6.7m0 0a18 18 0 0 1-17.5 0m0 0a9 9 0 0 1 .9-6.7"
    />
  </svg>
);

export const Trash = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m14.7 9-.3 9m-4.8 0-.3-9m10-3.2 1 .2m-1-.2-1.1 13.9a2.3 2.3 0 0 1-2.3 2H8.1a2.3 2.3 0 0 1-2.3-2l-1-14m14.4 0a48.1 48.1 0 0 0-3.4-.3M3.8 6l1-.2m0 0a48.1 48.1 0 0 1 3.5-.4m7.5 0v-1c0-1.1-1-2-2.1-2.1a52 52 0 0 0-3.4 0c-1.1 0-2 1-2 2.2v.9m7.5 0a48.7 48.7 0 0 0-7.5 0"
    />
  </svg>
);

export const Plus = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
  </svg>
);

export const LinkedIn = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40">
    <path d="M15 28.5h-3.7V16.8h3.6v11.7Zm-2-13.3c-1 0-2-1-2-2.1a2 2 0 0 1 3.1-1.8c.7.3 1 1 1 1.8a2 2 0 0 1-2 2.1Zm15.5 13.3h-3.6v-5.7c0-1.4 0-3-2-3-1.8 0-2.1 1.4-2.1 2.9v5.8h-3.6V16.8h3.4v1.6c.6-.9 1.8-1.9 3.5-1.9 3.7 0 4.4 2.4 4.4 5.6v6.4Z" />
  </svg>
);

export const Twitter = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    stroke="currentColor"
    strokeWidth="0"
    width="10px"
    viewBox="-200 -200 900 900">
    <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8l164.9-188.5L26.8 48h145.6l100.5 132.9L389.2 48zm-24.8 373.8h39.1L151.1 88h-42l255.3 333.8z" />
  </svg>
);

export const Youtube = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40">
    <path d="M29.9 15.4c-.2-1-1-1.7-1.9-2-1.7-.4-8.3-.4-8.3-.4s-6.7 0-8.4.5c-.9.2-1.6 1-1.8 1.9C9 17 9 20.5 9 20.5s0 3.5.5 5.2c.2 1 1 1.6 1.8 1.9 1.7.4 8.4.4 8.4.4s6.6 0 8.3-.4c1-.3 1.7-1 1.9-1.9.5-1.7.5-5.2.5-5.2s0-3.5-.5-5.1Zm-12.4 8.3v-6.3l5.6 3.1-5.6 3.2Z" />
  </svg>
);

export const Instagram = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40">
    <path d="M19.79 15.47a4.5 4.5 0 1 0 0 8.98 4.5 4.5 0 0 0 0-8.98Zm0 7.42a2.93 2.93 0 0 1-2.93-2.93 2.9 2.9 0 0 1 2.93-2.89c1.6 0 2.89 1.29 2.89 2.9a2.9 2.9 0 0 1-2.9 2.92Zm5.7-7.58a1.05 1.05 0 1 1-2.1 0c0-.58.46-1.05 1.05-1.05.58 0 1.05.47 1.05 1.05Zm2.97 1.06c-.08-1.4-.39-2.66-1.4-3.67-1.02-1.02-2.27-1.33-3.68-1.41-1.44-.08-5.78-.08-7.22 0-1.41.08-2.62.39-3.68 1.4-1.01 1.02-1.32 2.27-1.4 3.68-.08 1.44-.08 5.78 0 7.22.08 1.41.39 2.62 1.4 3.68a5.33 5.33 0 0 0 3.68 1.4c1.44.08 5.78.08 7.22 0 1.4-.08 2.66-.39 3.67-1.4a5.33 5.33 0 0 0 1.41-3.68c.08-1.44.08-5.78 0-7.22Zm-1.87 8.75a2.9 2.9 0 0 1-1.64 1.68c-1.18.47-3.91.35-5.16.35-1.29 0-4.02.12-5.16-.35a2.96 2.96 0 0 1-1.68-1.68c-.47-1.14-.35-3.87-.35-5.16 0-1.25-.12-3.98.35-5.16.32-.74.9-1.32 1.68-1.64 1.14-.46 3.87-.35 5.16-.35 1.25 0 3.98-.11 5.16.35.74.28 1.32.9 1.64 1.64.46 1.18.35 3.91.35 5.16 0 1.29.11 4.02-.35 5.16Z" />
  </svg>
);

export const Caret = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 14">
    <path
      fill="#75EEA9"
      d="M5.46991 7L0.133332 14L3.66343 14L9 7L3.66343 -2.33269e-07L0.133332 -3.87574e-07L5.46991 7Z"
    />
  </svg>
);

export const Phone = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M15 3.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0V5.56l-4.72 4.72a.75.75 0 1 1-1.06-1.06l4.72-4.72h-2.69a.75.75 0 0 1-.75-.75Z" />
    <path d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z" />
  </svg>
);

export const Email = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M17.834 6.166a8.25 8.25 0 1 0 0 11.668.75.75 0 0 1 1.06 1.06c-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788 3.807-3.808 9.98-3.808 13.788 0A9.722 9.722 0 0 1 21.75 12c0 .975-.296 1.887-.809 2.571-.514.685-1.28 1.179-2.191 1.179-.904 0-1.666-.487-2.18-1.164a5.25 5.25 0 1 1-.82-6.26V8.25a.75.75 0 0 1 1.5 0V12c0 .682.208 1.27.509 1.671.3.401.659.579.991.579.332 0 .69-.178.991-.579.3-.4.509-.99.509-1.671a8.222 8.222 0 0 0-2.416-5.834ZM15.75 12a3.75 3.75 0 1 0-7.5 0 3.75 3.75 0 0 0 7.5 0Z" />
  </svg>
);

export const Location = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
    />
  </svg>
);

export const Postcard = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M19.5 22.5a3 3 0 0 0 3-3v-8.174l-6.879 4.022 3.485 1.876a.75.75 0 1 1-.712 1.321l-5.683-3.06a1.5 1.5 0 0 0-1.422 0l-5.683 3.06a.75.75 0 0 1-.712-1.32l3.485-1.877L1.5 11.326V19.5a3 3 0 0 0 3 3h15Z" />
    <path d="M1.5 9.589v-.745a3 3 0 0 1 1.578-2.642l7.5-4.038a3 3 0 0 1 2.844 0l7.5 4.038A3 3 0 0 1 22.5 8.844v.745l-8.426 4.926-.652-.351a3 3 0 0 0-2.844 0l-.652.351L1.5 9.589Z" />
  </svg>
);

export const VideoPlay = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 120 120">
    <path
      fill="#75EEA9"
      d="M119.587 64.694c.529-.659.55-1.756.064-2.434L75.394.845C74.91.167 73.831-.172 72.986.087L1.48 23.504C.655 23.784-.02 24.661 0 25.5l1.564 79.526c.02.838.718 1.636 1.584 1.795l70.514 13.145c.866.16 1.986-.239 2.514-.918l43.411-54.353Z"
    />
    <path
      fill="#0F172A"
      fillRule="evenodd"
      d="M35.75 64c0-8.975 7.275-16.25 16.25-16.25S68.25 55.025 68.25 64 60.975 80.25 52 80.25 35.75 72.975 35.75 64Zm23.374-1.639c1.285.714 1.285 2.564 0 3.278l-9.338 5.188c-1.25.694-2.786-.21-2.786-1.64V58.813c0-1.43 1.536-2.333 2.786-1.639l9.338 5.188Z"
      clipRule="evenodd"
    />
  </svg>
);

export const VideoPlayRound = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 80 80">
    <path fill="#EC8F00" d="M40 0a40 40 0 1 0 0 80 40 40 0 0 0 0-80Z" />
    <path
      fill="white"
      fillRule="evenodd"
      d="M55.8 43.4a4.6 4.6 0 0 0 0-8l-23-12.8c-3-1.7-6.8.5-6.8 4v25.6c0 3.5 3.8 5.7 6.9 4l23-12.8Z"
      clipRule="evenodd"
      opacity="1"
    />
  </svg>
);

export const Arrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      d="M17 10c0 .4142-.3358.75-.75.75H5.61208l4.15775 3.9594c.29857.2871.30787.7619.02079 1.0604-.28709.2986-.76187.3079-1.06045.0208l-5.5-5.25C3.08311 10.3992 3 10.204 3 10c0-.20401.08311-.39922.23017-.54062l5.5-5.25c.29858-.2871.77336-.27779 1.06045.02079.28708.29858.27778.77336-.02079 1.06046L5.61208 9.25H16.25c.4142 0 .75.33579.75.75Z"
      clipRule="evenodd"
    />
  </svg>
);

const CaretDown = (
  <svg viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.5692 4.69852L0.997767 0.571415L0.997767 3.30146L6.5692 7.42856L12.1406 3.30146L12.1406 0.571416L6.5692 4.69852Z"
      fill="#001D3A"
    />
  </svg>
);

export const ExternalLinkIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
    <path
      fill="#002E5C"
      fillRule="evenodd"
      d="M4.3 5.5c-.5 0-.8.3-.8.8v8.4c0 .5.3.8.8.8h8.4c.5 0 .8-.3.8-.8v-4a.8.8 0 0 1 1.5 0v4c0 1.3-1 2.3-2.3 2.3H4.3C3 17 2 16 2 14.7V6.3C2 5 3 4 4.3 4h5a.8.8 0 0 1 0 1.5h-5Z"
      clipRule="evenodd"
    />
    <path
      fill="#002E5C"
      fillRule="evenodd"
      d="M6.2 12.8c.3.3.7.3 1 0l9.3-8.4v2.8a.8.8 0 0 0 1.5 0V2.8c0-.4-.3-.7-.8-.7h-4.4a.8.8 0 0 0 0 1.5h2.5l-9 8.2c-.4.3-.4.7-.1 1Z"
      clipRule="evenodd"
    />
  </svg>
);

export const FilterIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5"
    />
  </svg>
);

export const SearchIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      d="M8.5 1.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM.25 8.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 .25 8.5Z"
      clipRule="evenodd"
    />
  </svg>
);

const HamburgerIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      d="M2 4.8c0-.5.3-.8.8-.8h14.4a.8.8 0 0 1 0 1.5H2.8a.8.8 0 0 1-.8-.8ZM2 10c0-.4.3-.8.8-.8h14.4a.8.8 0 0 1 0 1.6H2.8A.8.8 0 0 1 2 10Zm0 5.3c0-.5.3-.8.8-.8h14.4a.8.8 0 0 1 0 1.5H2.8a.8.8 0 0 1-.8-.8Z"
      clipRule="evenodd"
    />
  </svg>
);

const Xmark = (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 20">
    <path d="M6.6 5.2a.8.8 0 0 0-1 1L9.1 10l-3.7 3.7a.7.7 0 1 0 1 1l3.8-3.6 3.7 3.7a.7.7 0 1 0 1-1L11.5 10 15 6.3a.7.7 0 0 0-1-1l-3.8 3.6-3.7-3.7Z" />
  </svg>
);

const Heart = (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <path
      fill="#001D3A"
      d="M11.6 21v-.1a8.6 8.6 0 0 1-.4-.2A22.8 22.8 0 0 1 7 17.5c-2.3-2.1-4.8-5.3-4.8-9.3A5.5 5.5 0 0 1 7.8 3c1.7 0 3.3.8 4.3 2 1-1.2 2.6-2 4.3-2 3 0 5.4 2.3 5.4 5.3 0 3.9-2.4 7-4.7 9.2a25.2 25.2 0 0 1-4.6 3.4c-.3.1-.5.1-.8 0Z"
    />
  </svg>
);

const Smiley = (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <path
      fill="#001D3A"
      fillRule="evenodd"
      d="M12 2.3a9.7 9.7 0 1 0 0 19.4 9.7 9.7 0 0 0 0-19.4Zm-2.6 6c-.6 0-.9.4-1 .6a2 2 0 0 0-.2.8l.2 1c.1.1.4.6 1 .6.5 0 .8-.5 1-.7l.1-.8c0-.3 0-.7-.2-1-.1-.1-.4-.6-1-.6Zm4.3.6c.1-.2.4-.7 1-.7.5 0 .8.5.9.7l.2.8-.2 1c-.1.1-.4.6-1 .6-.5 0-.8-.5-1-.7a2 2 0 0 1-.1-.8c0-.3 0-.7.2-1Zm2 6.8a.7.7 0 1 0-1-1 3.8 3.8 0 0 1-5.4 0 .7.7 0 0 0-1 1c2 2 5.4 2 7.4 0Z"
      clipRule="evenodd"
    />
  </svg>
);

const Netherlands = (
  <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 9 6">
    <path fill="#21468B" d="M0 0h9v6H0z" />
    <path fill="#FFF" d="M0 0h9v4H0z" />
    <path fill="#AE1C28" d="M0 0h9v2H0z" />
  </svg>
);

const Britain = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 30">
    <clipPath id="a">
      <path d="M0 0v30h60V0z" />
    </clipPath>
    <clipPath id="b">
      <path d="M30 15h30v15zv15H0zH0V0zV0h30z" />
    </clipPath>
    <g clipPath="url(#a)">
      <path d="M0 0v30h60V0z" fill="#012169" />
      <path d="M0 0l60 30m0-30L0 30" stroke="#fff" strokeWidth="6" />
      <path d="M0 0l60 30m0-30L0 30" clipPath="url(#b)" stroke="#C8102E" strokeWidth="4" />
      <path d="M30 0v30M0 15h60" stroke="#fff" strokeWidth="10" />
      <path d="M30 0v30M0 15h60" stroke="#C8102E" strokeWidth="6" />
    </g>
  </svg>
);

const Germany = (
  <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 5 3">
    <path d="M0 0h5v3H0z" />
    <path fill="#D00" d="M0 1h5v2H0z" />
    <path fill="#FFCE00" d="M0 2h5v1H0z" />
  </svg>
);

const Poland = (
  <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 16 10">
    <path fill="#fff" d="M0 0h16v10H0z" />
    <path fill="#dc143c" d="M0 5h16v5H0z" />
  </svg>
);

const Hashtag = (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <path
      fill="#001D3A"
      fillRule="evenodd"
      d="M11.1 1.5c.4.1.7.5.6.9l-1 5.1H15l1.1-5.4a.7.7 0 1 1 1.5.3l-1 5.1h3.6a.8.8 0 0 1 0 1.5h-4l-1.1 6h3.6a.8.8 0 0 1 0 1.5h-4l-1 5.4a.7.7 0 1 1-1.5-.3l1-5.1H9l-1.1 5.4a.8.8 0 1 1-1.5-.3l1-5.1H3.8a.8.8 0 0 1 0-1.5h3.8l1.2-6H5.2a.7.7 0 1 1 0-1.5h4l1-5.4c.1-.4.5-.7.9-.6ZM10.4 9l-1.2 6h4.4l1.2-6h-4.4Z"
      clipRule="evenodd"
    />
  </svg>
);

// TODO: Update all Icons to svg-values (just like Hamburger)  instead of components

export const icons = {
  hamburger: HamburgerIcon,
  globe: Globe,
  xmark: Xmark,
  heart: Heart,
  smiley: Smiley,
  hashtag: Hashtag,
  caretDown: CaretDown,
  netherlands: Netherlands,
  britain: Britain,
  germany: Germany,
  poland: Poland,
};

export type IconType = keyof typeof icons;

interface Properties {
  type: IconType;
}

export const Icon = ({ type }: Properties) => icons[type];
