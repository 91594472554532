import { groq } from 'next-sanity';

export const createImageTitleAndAltFallbacks = (withFileNameFallBack = false) => groq`
  "title": coalesce(title, caption, asset->title ${
    withFileNameFallBack ? ", string::split(asset->originalFilename,'.')[0]" : ''
  }),
  "alt": coalesce(alt,asset->altText,string::split(asset->originalFilename,'.')[0]),
`;

export const imageQuery = groq`
image {
  ...,
  ${createImageTitleAndAltFallbacks(true)}
}
`;
