import dynamic from 'next/dynamic';

type HeroIconProperties = {
  icon: string;
};

export const HeroIcon = ({ icon }: HeroIconProperties) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const Icon = dynamic(() => import('@heroicons/react/24/solid').then((module_) => module_[icon]));
  // eslint-disable-next-line unicorn/no-null
  return Icon ? <Icon /> : null;
};
