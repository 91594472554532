import { groq } from 'next-sanity';
import { createImageTitleAndAltFallbacks } from '../../queries/helpers/image';
import { SanityImageType } from '../../queries/helpers/interfaces';

// --------------
// The previewimage is used to display in a grid. The editor can override the preview image by selecting an overview image.
// --------------

export const mediaItemsQuery = groq`
mediaItems[] {
  ...,
  ${createImageTitleAndAltFallbacks()}
},
"previewImage": coalesce(overviewImage, select(mediaItems[_type == 'image'])[0])
`;

interface SanityVideoType {
  _type: 'video';
  autoPlay?: boolean;
  vimeoVideoUrl?: string;
  youtubeVideoUrl?: string;
}
interface ImageType extends Sanity.Keyed<SanityImageType> {
  _type?: 'image';
}

export type MediaItemsType = Array<ImageType | SanityVideoType>;
export type PreviewImageType = SanityImageType;
