import { useMemo } from 'react';
import cx from 'classnames';
import styles from './TitleOverTwoRules.module.css';

interface Properties {
  as?: 'h1' | 'h2' | 'h3' | 'h4';
  children?: string;
  hasGreenUnderline?: boolean;
}

export const TitleOverTwoRules = ({ as = 'h1', children, hasGreenUnderline }: Properties) => {
  const As = as;
  const __html = useMemo(() => {
    const splittedChildren = children?.replace('*', '<strong>').replace('*', '</strong>').replaceAll('--', '<br/>');
    return splittedChildren || '';
  }, [children]);

  return (
    <As
      // eslint-disable-next-line react/forbid-component-props
      className={cx(styles.title, { [styles.hasGreenUnderline]: hasGreenUnderline })}
      dangerouslySetInnerHTML={{ __html }}
    />
  );
};
